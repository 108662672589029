import axios from 'axios';

import auth from './auth';
import documentProject from './documents/project';
import roles from './roles';

/**
 * API делится по микросервисам
 * Микросервис -> функция
 * Исключение: авторизация и профиль, они называются просто как логичнее
 */
export default {
  auth: {
    ...auth,
    login: ({ confirmation, ...data }) => axios.post('/auth/api/v1/login', data, {
      headers: { 'Messaggio-OTP': confirmation },
    }),
    registration: data => axios.post('/api-gateway/api/v1/users', data),
    completed: data => axios.post('/entities/api/v1/users/completed', data),
    password: {
      restore: data => axios.post('/entities/api/v1/users/-/password/restore', data),
      set: data => axios.post('/entities/api/v1/users/-/password/set', data),
    },
    verifyEmail: ({ token }) => axios.post(`/entities/api/v1/users/verify/${token}`),
  },
  contacts: {
    files: {
      post: data => axios.post('/contacts/api/v1/files', data),
    },
    search: {
      contact: {
        list: (page = 1, page_size = 10, order_by = null, options) => axios.get('/contacts/api/v1/search/contacts', {
          params: { page, page_size, order_by, ...options },
        }),
      },
      group: {
        list: (page = 1, page_size = 10, order_by = null, options) => axios.get('/contacts/api/v1/search/groups', {
          params: { page, page_size, order_by, ...options },
        }),
      },
    },
    group: {
      list: (page = 1, page_size = 10, order_by = null, options) => axios.get('/contacts/api/v1/groups', {
        params: { page, page_size, order_by, ...options },
      }),
      post: data => axios.post('/contacts/api/v1/groups', data),
      get: (gid, cache = false) => axios.get(`/contacts/api/v1/groups/${gid}`, { cache }),
      put: (gid, data) => Promise.all([
        axios.put(`/contacts/api/v1/groups/${gid}`, data),
        // axios.put(`/contacts/api/v1/groups/${gid}/status`, data),
        // axios.put(`/contacts/api/v1/groups/${gid}/type`, data),
      ]),
      delete: gid => axios.delete(`/contacts/api/v1/groups/${gid}`),
      duplicatesRemove: gid => axios.delete(`/contacts/api/v1/groups/${gid}/duplicates:remove`),
      copy: (gid, data) => axios.post(`/contacts/api/v1/groups/${gid}/contacts/copy`, data),
      files: {
        post: (gid, fid) => axios.post(`/contacts/api/v1/groups/${gid}/files/${fid}`),
        params: {
          get: (gid, fid, params) => axios.get(`/contacts/api/v1/groups/${gid}/files/${fid}/params`, { params }),
          post: (gid, fid, data) => axios.post(`/contacts/api/v1/groups/${gid}/files/${fid}/params`, data),
        },
        processing: {
          get: (gid, fid, pid) => axios.get(`/contacts/api/v1/groups/${gid}/files/${fid}/processing/${pid}`),
        },
      },
      contact: {
        list: (gid, page = 1, page_size = 10, {
          cache = false,
          ...options
        } = {}) => axios.get(`/contacts/api/v1/groups/${gid}/contacts`, {
          cache, params: { page, page_size, ...options },
        }),
        post: (gid, data) => axios.post(`/contacts/api/v1/groups/${gid}/contacts`, data),
        get: (gid, cid) => axios.get(`/contacts/api/v1/groups/${gid}/contacts/${cid}`),
        put: (gid, cid, data) => axios.put(`/contacts/api/v1/groups/${gid}/contacts/${cid}`, data),
        delete: (gid, cid) => axios.delete(`/contacts/api/v1/groups/${gid}/contacts/${cid}`),
        deleteGroup: (gid, data) => axios.delete(`/contacts/api/v1/groups/${gid}/contacts`, { data }),
      },
      load: {
        text: {
          post: (gid, data) => axios.post(`/contacts/api/v1/groups/${gid}/load/text`, data),
        },
      },
      dump: {
        list: (gid, page = 1, page_size = 10, options) => axios.get(`/contacts/api/v1/groups/${gid}/dumps`, {
          params: { page, page_size, ...options },
        }),
        post: gid => axios.post(`/contacts/api/v1/groups/${gid}/dumps`),
        get: (gid, did) => axios.get(`/contacts/api/v1/groups/${gid}/dumps/${did}`),
        download: (gid, did) => axios.get(`/contacts/api/v1/groups/${gid}/dumps/${did}/download`),
      },
    },
    blackgroup: {
      list: (page = 1, page_size = 10, {
        company_id = null,
        type = null,
      } = {}) => axios.get('/contacts/api/v1/blackgroups', {
        params: { page, page_size, tenant_id: company_id, type },
      }),
      post: data => axios.post('/contacts/api/v1/blackgroups', data),
      get: gid => axios.get(`/contacts/api/v1/blackgroups/${gid}`),
      put: (gid, data) => axios.put(`/contacts/api/v1/blackgroups/${gid}`, data),
      delete: gid => axios.delete(`/contacts/api/v1/blackgroups/${gid}`),
      duplicatesRemove: gid => axios.delete(`/contacts/api/v1/blackgroups/${gid}/duplicates:remove`),
      blackphone: {
        list: (gid, page = 1, page_size = 10) => axios.get(`/contacts/api/v1/blackgroups/${gid}/blackphones`, {
          params: { page, page_size },
        }),
        post: (gid, data) => axios.post(`/contacts/api/v1/blackgroups/${gid}/blackphones`, data),
        get: (gid, cid) => axios.get(`/contacts/api/v1/blackgroups/${gid}/blackphones/${cid}`),
        put: (gid, cid, data) => axios.put(`/contacts/api/v1/blackgroups/${gid}/blackphones/${cid}`, data),
        delete: (gid, cid) => axios.delete(`/contacts/api/v1/blackgroups/${gid}/blackphones/${cid}`),
        deleteGroup: (gid, data) => axios.delete(`/contacts/api/v1/blackgroups/${gid}/blackphones`, { data }),
        block: (gid, data) => axios.post(`/contacts/api/v1/blackgroups/${gid}/blackphones/block`, data),
      },
    },
    dispatche: {
      post: data => axios.post('/contacts/api/v1/dispatches', data),
      info: id => axios.get(`/contacts/api/v1/dispatches/${id}/info`),
      progress: id => axios.get(`/contacts/api/v1/dispatches/${id}/progress`),
    },
  },
  entities: {
    tenant: {
      list: (page = 1, page_size = 10, order_by = null, options) => axios.get('/entities/api/v1/tenants', {
        params: { page, page_size, order_by, ...options },
      }),
      post: data => axios.post('/entities/api/v1/tenants', data),
      get: id => axios.get(`/entities/api/v1/tenants/${id}`),
      put: (id, data) => axios.put(`/entities/api/v1/tenants/${id}`, data),
      delete: id => axios.delete(`/entities/api/v1/tenants/${id}`),
      status: (id, status) => axios.put(`/entities/api/v1/tenants/${id}/status`, { name: status }),
      profile: {
        get: () => axios.get('/entities/api/v1/tenants/-/profile'),
        put: data => axios.put('/entities/api/v1/tenants/-/profile', data),
      },
    },
    user: {
      list: (page = 1, page_size = 10, order_by = null, options) => axios.get('/entities/api/v1/users', {
        params: { page, page_size, order_by, ...options },
      }),
      post: data => axios.post('/entities/api/v1/users', data),
      profile: {
        get: () => axios.get('/entities/api/v1/users/-/profile'),
      },
      get: (id, cache = false) => axios.get(`/entities/api/v1/users/${id}`, { cache }),
      put: (id, data) => axios.put(`/entities/api/v1/users/${id}`, data),
      passwordUpdate: (id, data) => axios.put(`/entities/api/v1/users/${id}/password/update`, data),
      delete: id => axios.delete(`/entities/api/v1/users/${id}`),
      status: (id, status) => axios.put(`/entities/api/v1/users/${id}/status`, { name: status }),
      resendInvitation: id => axios.post(`/entities/api/v1/users/${id}/resend_invitation`),
      verifyResend: id => axios.post(`/entities/api/v1/users/${id}/verify/resend`),
      avatar: {
        update: (id, {
          avatar_image_url,
          confirmation = '',
        }) => axios.put(`/entities/api/v1/users/${id}/avatar/update`, { avatar_image_url }, { headers: { 'Messaggio-OTP': confirmation } }),
      },
      phone: {
        verification: {
          post: (id, data) => axios.post(`/entities/api/v1/users/${id}/phone/verification`, data),
          code: (id, data) => axios.post(`/entities/api/v1/users/${id}/phone/verification/code`, data),
        },
        delete: id => axios.delete(`/entities/api/v1/users/${id}/phone`),
        update: data => axios.put('/entities/api/v1/users/phone/update', data),
      },
      authenticator: {
        secret: id => axios.post(`/entities/api/v1/users/${id}/authenticator/secret`),
        secretConfirm: (id, data) => axios.post(`/entities/api/v1/users/${id}/authenticator/secret/confirm`, data),
        disable: (id, { confirmation }) => axios.post(`/entities/api/v1/users/${id}/authenticator/disable`, {}, { headers: { 'Messaggio-OTP': confirmation } }),
      },
    },
  },
  tenant_action_history: {
    entity: {
      list: (page = 1, page_size = 10, order_by = null, options) => axios.get('/tenant-action-histories/api/v1/entities', {
        params: { page, page_size, order_by, ...options },
      }),
      post: data => axios.post('/tenant-action-histories/api/v1/entities', data),
      get: id => axios.get(`/tenant-action-histories/api/v1/entities/${id}`),
      put: (id, data) => axios.put(`/tenant-action-histories/api/v1/entities/${id}`, data),
      delete: id => axios.delete(`/tenant-action-histories/api/v1/entities/${id}`),
    },
    entity_model: {
      list: (page = 1, page_size = 10, order_by = null, options) => axios.get('/tenant-action-histories/api/v1/entity-models', {
        params: { page, page_size, order_by, ...options },
      }),
      post: data => axios.post('/tenant-action-histories/api/v1/entity-models', data),
      get: id => axios.get(`/tenant-action-histories/api/v1/entity-models/${id}`),
      put: (id, data) => axios.put(`/tenant-action-histories/api/v1/entity-models/${id}`, data),
      delete: id => axios.delete(`/tenant-action-histories/api/v1/entity-models/${id}`),
    },
    event: {
      list: (page = 1, page_size = 10, order_by = null, options) => axios.get('/tenant-action-histories/api/v1/events', {
        params: { page, page_size, order_by, ...options },
      }),
      post: data => axios.post('/tenant-action-histories/api/v1/events', data),
      get: id => axios.get(`/tenant-action-histories/api/v1/events/${id}`),
      put: (id, data) => axios.put(`/tenant-action-histories/api/v1/events/${id}`, data),
    },
    event_log: {
      list: (page = 1, page_size = 10, order_by = null, options) => axios.get('/tenant-action-histories/api/v1/event-logs', {
        params: { page, page_size, order_by, ...options },
      }),
      get: id => axios.get(`/tenant-action-histories/api/v1/event-logs/${id}`),
    },
  },
  documents: {
    contract: {
      list: (page = 1, page_size = 10, order_by = null, options) => axios.get('/documents/api/v1/contracts', {
        params: { page, page_size, order_by, ...options },
      }),
      post: data => axios.post('/documents/api/v1/contracts', data),
      get: id => axios.get(`/documents/api/v1/contracts/${id}`),
      put: (id, data) => axios.put(`/documents/api/v1/contracts/${id}`, data),
      delete: id => axios.delete(`/documents/api/v1/contracts/${id}`),
      attach: {
        project: (cid, pid) => axios.put(`/documents/api/v1/contracts/${cid}/projects/${pid}`),
      },
    },
    project: {
      ...documentProject,
      listByTenant: (tenant_id, page = 1, page_size = 10) => axios.get(`/documents/api/v1/projects/-/tenants/${tenant_id}`, {
        params: { page, page_size },
      }),
      post: data => axios.post('/documents/api/v1/projects', data),
      put: (id, data) => axios.put(`/documents/api/v1/projects/${id}`, data),
      delete: id => axios.delete(`/documents/api/v1/projects/${id}`),
      dispatch_document: (id, page = 1, page_size = 10, order_by = null, options) => axios.get(`/documents/api/v1/projects/${id}/dispatch_documents`, {
        params: { page, page_size, order_by, ...options },
      }),
      templates: {
        list: (id, page = 1, page_size = 10, order_by = null, options) => axios.get(`/documents/api/v1/projects/${id}/templates`, {
          params: { page, page_size, order_by, ...options },
        }),
        put: (pid, tid, data) => axios.put(`/documents/api/v1/projects/${pid}/templates/${tid}`, data),
      },
      clientsenders: (pid, page = 1, page_size = 10, order_by = null, options) => axios.get(`/documents/api/v1/projects/${pid}/clientsenders`, {
        params: { page, page_size, order_by, ...options },
      }),
      contents: (id, page = 1, page_size = 10, options) => axios.get(`/documents/api/v1/projects/${id}/contents`, {
        params: { page, page_size, ...options },
      }),
    },
    channel: {
      list: (page = 1, page_size = 10, order_by = null, {
        cache = false,
        ...options
      } = {}) => axios.get('/documents/api/v1/channels', {
        cache, params: { page, page_size, order_by, ...options },
      }),
      post: data => axios.post('/documents/api/v1/channels', data),
      get: id => axios.get(`/documents/api/v1/channels/${id}`, { cache: true }),
      put: (id, data) => axios.put(`/documents/api/v1/channels/${id}`, data),
      delete: id => axios.delete(`/documents/api/v1/channels/${id}`),
      gates: id => axios.get(`/documents/api/v1/channels/${id}/gates`),
    },
    gate: {
      list: (page = 1, page_size = 10, order_by = null) => axios.get('/documents/api/v1/gates', {
        params: { page, page_size, order_by },
      }),
      post: data => axios.post('/documents/api/v1/gates', data),
      get: id => axios.get(`/documents/api/v1/gates/${id}`),
      put: (id, data) => axios.put(`/documents/api/v1/gates/${id}`, data),
      delete: id => axios.delete(`/documents/api/v1/gates/${id}`),
      channel: {
        get: id => axios.get(`/documents/api/v1/gates/${id}/channels`),
      },
    },
    gate_content: {
      list: (page = 1, page_size = 10, order_by = null, options) => axios.get('/documents/api/v1/gate_contents', {
        params: { page, page_size, order_by, ...options },
      }),
      statuses: () => axios.get('/documents/api/v1/gate_contents/statuses'),
      post: data => axios.post('/documents/api/v1/gate_contents', data),
      get: id => axios.get(`/documents/api/v1/gate_contents/${id}`),
      put: (id, data) => axios.put(`/documents/api/v1/gate_contents/${id}`, data),
      delete: id => axios.delete(`/documents/api/v1/gate_contents/${id}`),
      status: {
        put: (id, data) => axios.put(`/documents/api/v1/gate_contents/${id}/statuses`, data),
      },
      content: {
        list: (id, page = 1, page_size = 10, order_by = null, options) => axios.get(`/documents/api/v1/gate_contents/${id}/contents`, {
          params: { page, page_size, order_by, ...options },
        }),
      },
    },
    sender: {
      list: (page = 1, page_size = 10, order_by = null, options) => axios.get('/documents/api/v1/senders', {
        params: { page, page_size, order_by, ...options },
      }),
      post: data => axios.post('/documents/api/v1/senders', data),
      get: id => axios.get(`/documents/api/v1/senders/${id}`),
      put: (id, data) => axios.put(`/documents/api/v1/senders/${id}`, data),
      put_statuses: (id, status) => axios.put(`/documents/api/v1/senders/${id}/statuses`, { status }),
      delete: id => axios.delete(`/documents/api/v1/senders/${id}`),
      status: {
        put: (id, data) => axios.put(`/documents/api/v1/senders/${id}/statuses`, data),
      },
      clientsender: {
        list: (sender_id, page = 1, page_size = 10, order_by = null) => axios.get(`/documents/api/v1/senders/${sender_id}/clientsenders`, {
          params: { page, page_size, order_by },
        }),
      },
      gate_contents: {
        list: (sender_id, page = 1, page_size = 10, order_by = null) => axios.get(`/documents/api/v1/senders/${sender_id}/gate_contents`, {
          params: { page, page_size, order_by },
        }),
      },
    },
    code: {
      list: (page = 1, page_size = 10, order_by = null) => axios.get('/documents/api/v1/codes', {
        params: { page, page_size, order_by },
      }),
      post: data => axios.post('/documents/api/v1/codes', data),
      get: id => axios.get(`/documents/api/v1/codes/${id}`),
      put: (id, data) => axios.put(`/documents/api/v1/codes/${id}`, data),
      delete: id => axios.delete(`/documents/api/v1/codes/${id}`),
    },
    template: {
      list: (page = 1, page_size = 10, order_by = null, options) => axios.get('/documents/api/v1/templates', {
        params: { page, page_size, order_by, ...options },
      }),
      post: data => axios.post('/documents/api/v1/templates', data),
      get: (id, cache = false) => axios.get(`/documents/api/v1/templates/${id}`, { cache }),
      put: (id, data) => axios.put(`/documents/api/v1/templates/${id}`, data),
      delete: id => axios.delete(`/documents/api/v1/templates/${id}`),
      statuses: {
        get: () => axios.get('/documents/api/v1/templates/statuses'),
      },
      content: {
        list: (tid, page = 1, page_size = 10) => axios.get(`/documents/api/v1/templates/${tid}/contents`, {
          params: { page, page_size },
        }),
        get: (tid, cid) => axios.get(`/documents/api/v1/templates/${tid}/contents/${cid}`),
        post: (tid, data) => axios.post(`/documents/api/v1/templates/${tid}/contents`, data),
        put: (tid, cid, data) => axios.put(`/documents/api/v1/templates/${tid}/contents/${cid}`, data),
        delete: (tid, cid) => axios.delete(`/documents/api/v1/templates/${tid}/contents/${cid}`),
        layout: {
          list: (tid, cid, options) => axios.get(`/documents/api/v1/templates/${tid}/contents/${cid}/layout`, options),
          put: (tid, cid, data) => axios.put(`/documents/api/v1/templates/${tid}/contents/${cid}/layout`, data),
          delete: (tid, cid) => axios.delete(`/documents/api/v1/templates/${tid}/contents/${cid}/layout`),
        },
      },
      layout: {
        post: (tid, data) => axios.post(`/documents/api/v1/templates/${tid}/layout`, data),
      },
      status: {
        put: (id, data) => axios.put(`/documents/api/v1/templates/${id}/statuses`, data),
        moderation: (id, data) => axios.put(`/documents/api/v1/templates/${id}/statuses/moderation`, data),
      },
    },
    content: {
      list: (page = 1, page_size = 10, order_by = null, options) => axios.get('/documents/api/v1/contents', {
        params: { page, page_size, order_by, ...options },
      }),
      post: data => axios.post('/documents/api/v1/contents', data),
      get: id => axios.get(`/documents/api/v1/contents/${id}`),
      put: (id, data) => axios.put(`/documents/api/v1/contents/${id}`, data),
      delete: id => axios.delete(`/documents/api/v1/contents/${id}`),
      status: {
        put: (id, data) => axios.put(`/documents/api/v1/contents/${id}/statuses`, data),
      },
      gate_content: {
        post: (id, gcid) => axios.post(`/documents/api/v1/contents/${id}/gate_contents/${gcid}/attach`),
        delete: (id, gcid) => axios.delete(`/documents/api/v1/contents/${id}/gate_contents/${gcid}/detach`),
      },
    },
    routeOpen: {
      list: (page = 1, page_size = 10, order_by = null, options) => axios.get('/documents/api/v1/routes', {
        params: { page, page_size, order_by, ...options },
      }),
      post: data => axios.post('/documents/api/v1/routes', data),
      get: id => axios.get(`/documents/api/v1/routes/${id}`),
      put: (id, data) => axios.put(`/documents/api/v1/routes/${id}`, data),
      delete: id => axios.delete(`/documents/api/v1/routes/${id}`),
      status: {
        put: (id, data) => axios.put(`/documents/api/v1/routes/${id}/statuses`, data),
      },
      destination: {
        list: (page = 1, page_size = 10, id) => axios.get(`/documents/api/v1/routes/${id}/destinations`, {
          params: { page, page_size },
        }),
        post: data => axios.post('/documents/api/v1/destinations', data),
        attach: (route_id, destination_id) => axios.post(`/documents/api/v1/routes/${route_id}/destinations/${destination_id}/attach`),
        put: (id, data) => axios.put(`/documents/api/v1/destinations/${id}`, data),
        delete: id => axios.delete(`/documents/api/v1/destinations/${id}`),
        detach: (route_id, destination_id) => axios.delete(`/documents/api/v1/routes/${route_id}/destinations/${destination_id}/detach`),
      },
    },
    layout: {
      list: (page = 1, page_size = 10, order_by = null, options) => axios.get('/documents/api/v1/layouts', {
        params: { page, page_size, order_by, ...options },
      }),
      post: data => axios.post('/documents/api/v1/layouts', data),
      get: id => axios.get(`/documents/api/v1/layouts/${id}`),
      put: (id, data) => axios.put(`/documents/api/v1/layouts/${id}`, data),
      delete: id => axios.delete(`/documents/api/v1/layouts/${id}`),
    },
    dispatch_document: {
      list: (page = 1, page_size = 10, order_by = null, options) => axios.get('/documents/api/v1/dispatch_documents', {
        params: { page, page_size, order_by, ...options },
      }),
      post: data => axios.post('/documents/api/v1/dispatch_documents', data),
      get: (id, cache = false) => axios.get(`/documents/api/v1/dispatch_documents/${id}`, { cache }),
      put: (id, data) => axios.put(`/documents/api/v1/dispatch_documents/${id}`, data),
      delete: id => axios.delete(`/documents/api/v1/dispatch_documents/${id}`),
      restore: id => axios.put(`/documents/api/v1/dispatch_documents/${id}/restore`),
      info: (id, cache = false) => axios.get(`/contacts/api/v1/dispatches/${id}/info`, { cache }),
      content: (id, options = {}) => axios.get(`/documents/api/v1/dispatch_documents/${id}/content`, options),
      duration_in_hours: {
        put: (id, data) => axios.put(`/documents/api/v1/dispatch_documents/${id}/duration_in_hours`, data),
      },
      is_smart: {
        put: (id, data) => axios.put(`/documents/api/v1/dispatch_documents/${id}/is_smart`, data),
      },
      contacts: {
        list: (id, page = 1, page_size = 10, order_by = null, ...options) => axios.get(`/documents/api/v1/dispatch_documents/${id}/contacts`, {
          params: { page, page_size, order_by, ...options },
        }),
        attach: (id, contacts) => axios.post(`/documents/api/v1/dispatch_documents/${id}/contacts`, { contacts }),
        detach: (id, contacts) => axios.delete(`/documents/api/v1/dispatch_documents/${id}/contacts/byContactId`, { data: { contacts } }),
      },
      dispatches: {
        status: id => axios.get(`/documents/api/v1/dispatch_documents/dispatches/${id}/status`),
      },
      statuses: {
        list: () => axios.get('/documents/api/v1/dispatch_documents/statuses', { cache: true }),
        put: (id, status) => axios.put(`/documents/api/v1/dispatch_documents/${id}/statuses`, { status }),
      },
      attach: {
        project: (id, pid) => axios.put(`/documents/api/v1/dispatch_documents/${id}/project/${pid}`),
        content: (id, cid) => axios.put(`/documents/api/v1/dispatch_documents/${id}/content/${cid}`),
        destinations: (id, destId) => axios.post(`/documents/api/v1/dispatch_documents/${id}/destinations/${destId}`),
      },
      layout: (id, cid) => axios.get(`/documents/api/v1/dispatch_documents/${id}/content/${cid}/layout`),
      destination: {
        list: (id, page = 1, page_size = 10, order_by = null, {
          cache = false,
          ...options
        } = {}) => axios.get(`/documents/api/v1/dispatch_documents/${id}/destinations`, {
          cache, params: { page, page_size, order_by, ...options },
        }),
        post: (id, data) => axios.post(`/documents/api/v1/dispatch_documents/${id}/destinations`, data),
      },
    },
    dispatch_destination: {
      list: (page = 1, page_size = 10, order_by = null, options) => axios.get('/documents/api/v1/dispatch_destinations', {
        params: { page, page_size, order_by, ...options },
      }),
      post: data => axios.post('/documents/api/v1/dispatch_destinations', data),
      get: id => axios.get(`/documents/api/v1/dispatch_destinations/${id}`),
      put: (id, data) => axios.put(`/documents/api/v1/dispatch_destinations/${id}`, data),
      delete: id => axios.delete(`/documents/api/v1/dispatch_destinations/${id}`),
    },
    clientsender: {
      list: (page = 1, page_size = 10, order_by = null, options) => axios.get('/documents/api/v1/clientsenders', {
        params: { page, page_size, order_by, ...options },
      }),
      post: data => axios.post('/documents/api/v1/clientsenders', data),
      get: (id, cache = false) => axios.get(`/documents/api/v1/clientsenders/${id}`, { cache }),
      put: (id, data) => axios.put(`/documents/api/v1/clientsenders/${id}`, data),
      delete: id => axios.delete(`/documents/api/v1/clientsenders/${id}`),
      statuses: {
        list: () => axios.get('/documents/api/v1/clientsenders/statuses', { cache: true }),
        put: (id, status) => axios.put(`/documents/api/v1/clientsenders/${id}/statuses`, { status }),
        moderation: id => axios.put(`/documents/api/v1/clientsenders/${id}/statuses/moderation`),
      },
      comparison_sender: {
        put: (id, csid) => axios.put(`/documents/api/v1/clientsenders/${id}/senders/${csid}`),
        delete: (id, csid) => axios.delete(`/documents/api/v1/clientsenders/${id}/senders/${csid}`),
      },
    },
  },
  templates: {
    object: {
      list: () => axios.get('/templates/api/v1/objects'),
      getByName: name => axios.get(`/templates/api/v1/objects/${name}`),
    },
    preset: {
      list: () => axios.get('/templates/api/v1/presets'),
      post: data => axios.post('/templates/api/v1/presets', data),
      get: id => axios.get(`/templates/api/v1/presets/${id}`),
      delete: id => axios.delete(`/templates/api/v1/presets/${id}`),
    },
  },
  dispatcher: {
    dispatches: {
      post: id => axios.post(`/dispatcher/api/v1/dispatches/${id}`),
      progress: id => axios.get(`/dispatcher/api/v1/dispatches/${id}/progress`),
      status: id => axios.put(`/dispatcher/api/v1/dispatches/${id}/status`),
    },
  },
  // https://dev-admin.messaggio.com/ - dev
  // http://localhost:3000/devapi2/ - dev
  statistics: {
    stats: {
      post: (data, options) => axios.post('/dispatch-stats/api/v1/stats', data, options),
    },
    reports: {
      list: (page = 1, page_size = 10, order_by = null, options) => axios.get('/reports/api/v1/reports', {
        params: { page, page_size, order_by, ...options },
      }),
      get: id => axios.get(`/reports/api/v1/reports/${id}`),
      download: (id, config) => axios.get(`/reports/api/v1/reports/${id}/csv/download`, {
        ...config,
        responseType: 'blob',
      }),
      delete: id => axios.delete(`/reports/api/v1/reports/${id}`),
    },
  },
  sniffer: {
    task: {
      list: (page = 1, page_size = 10, order_by = null, options) => axios.get('/sniffer/api/v1/tasks', {
        params: { page, page_size, order_by, ...options },
      }),
      post: data => axios.post('/sniffer/api/v1/tasks', data),
      get: id => axios.get(`/sniffer/api/v1/tasks/${id}`),
      delete: id => axios.delete(`/sniffer/api/v1/tasks/${id}`),
    },
    suspect: {
      message: {
        list: (page = 1, page_size = 10, order_by = null, options) => axios.get('/sniffer/api/v1/suspect/messages', {
          params: { page, page_size, order_by, ...options },
        }),
        list_service_id_group: params => axios.get('/sniffer/api/v1/suspect/messages/service_id_group', { params }),
        list_triggered_pattern_label_group: params => axios.get('/sniffer/api/v1/suspect/messages/triggered_pattern_label_group', { params }),
        post: data => axios.post('/sniffer/api/v1/suspect/messages', data),
        get: id => axios.get(`/sniffer/api/v1/suspect/messages/${id}`),
        put: (id, data) => axios.put(`/sniffer/api/v1/suspect/messages/${id}`, data),
        delete: id => axios.delete(`/sniffer/api/v1/suspect/messages/${id}`),
      },
      word: {
        list: (page = 1, page_size = 10, order_by = null, options) => axios.get('/sniffer/api/v1/suspect/words', {
          params: { page, page_size, order_by, ...options },
        }),
        post: data => axios.post('/sniffer/api/v1/suspect/words', data),
        get: id => axios.get(`/sniffer/api/v1/suspect/words/${id}`),
        put: (id, data) => axios.put(`/sniffer/api/v1/suspect/words/${id}`, data),
        delete: id => axios.delete(`/sniffer/api/v1/suspect/words/${id}`),
      },
    },
    exception: {
      word: {
        list: (page = 1, page_size = 10, order_by = null, options) => axios.get('/sniffer/api/v1/exception/words', {
          params: { page, page_size, order_by, ...options },
        }),
        list_service_id_group: params => axios.get('/sniffer/api/v1/exception/words/service_id_group', { params }),
        post: data => axios.post('/sniffer/api/v1/exception/words', data),
        get: id => axios.get(`/sniffer/api/v1/exception/words/${id}`),
        delete: id => axios.delete(`/sniffer/api/v1/exception/words/${id}`),
      },
    },
  },
  librarium: {
    language: {
      list: (page = 1, per_page = 10, order_by = null, options) => axios.get('/librarium/api/v1/sys/languages', {
        params: { page, per_page, order_by, ...options },
      }),
      search: (page = 1, per_page = 10, order_by = null, q, options) => axios.get('/librarium/api/v1/sys/languages/search', {
        params: { page, per_page, order_by, q, ...options },
      }),
      get: id => axios.get(`/librarium/api/v1/sys/languages/${id}`, { cache: true }),
      get_stages: code => axios.get(`/librarium/api/v1/sys/languages/${code}/stages`, { cache: true }),
      stage: {
        post: data => axios.post('/librarium/api/v1/sys/languages/stages', data),
        get: id => axios.get(`/librarium/api/v1/sys/languages/stages/${id}`, { cache: true }),
        put: (id, data) => axios.put(`/librarium/api/v1/sys/languages/stages/${id}`, data),
        delete: id => axios.delete(`/librarium/api/v1/sys/languages/stages/${id}`),
      },
    },
    country: {
      list: (page = 1, per_page = 10, order_by = null, options) => axios.get('/librarium/api/v1/sys/countries', {
        params: { page, per_page, order_by, ...options },
      }),
      search: (page = 1, per_page = 10, order_by = null, q, options) => axios.get('/librarium/api/v1/sys/countries/search', {
        params: { page, per_page, order_by, q, ...options },
      }),
      get: id => axios.get(`/librarium/api/v1/sys/countries/${id}`, { cache: true }),
      get_stages: code => axios.get(`/librarium/api/v1/sys/countries/${code}/stages`, { cache: true }),
      stage: {
        post: data => axios.post('/librarium/api/v1/sys/countries/stages', data),
        get: id => axios.get(`/librarium/api/v1/sys/countries/stages/${id}`, { cache: true }),
        put: (id, data) => axios.put(`/librarium/api/v1/sys/countries/stages/${id}`, data),
        delete: id => axios.delete(`/librarium/api/v1/sys/countries/stages/${id}`),
      },
    },
    country_group: {
      list: (page = 1, per_page = 10, order_by = null, options) => axios.get('/librarium/api/v1/sys/country_groups', {
        params: { page, per_page, order_by, ...options },
      }),
      search: (page = 1, per_page = 10, order_by = null, q, options) => axios.get('/librarium/api/v1/sys/country_groups/search', {
        params: { page, per_page, order_by, q, ...options },
      }),
      get: id => axios.get(`/librarium/api/v1/sys/country_groups/${id}`, { cache: true }),
      get_stages: code => axios.get(`/librarium/api/v1/sys/country_groups/${code}/stages`, { cache: true }),
      stage: {
        post: data => axios.post('/librarium/api/v1/sys/country_groups/stages', data),
        get: id => axios.get(`/librarium/api/v1/sys/country_groups/stages/${id}`, { cache: true }),
        put: (id, data) => axios.put(`/librarium/api/v1/sys/country_groups/stages/${id}`, data),
        delete: id => axios.delete(`/librarium/api/v1/sys/country_groups/stages/${id}`),
      },
    },
    timezone: {
      list: (page = 1, per_page = 10, order_by = null, options) => axios.get('/librarium/api/v1/sys/timezones', {
        params: { page, per_page, order_by, ...options },
      }),
      search: (page = 1, per_page = 10, order_by = null, q, options) => axios.get('/librarium/api/v1/sys/timezones/search', {
        params: { page, per_page, order_by, q, ...options },
      }),
      get: id => axios.get(`/librarium/api/v1/sys/timezones/${id}`, { cache: true }),
      get_stages: code => axios.get(`/librarium/api/v1/sys/timezones/${code}/stages`, { cache: true }),
      stage: {
        post: data => axios.post('/librarium/api/v1/sys/timezones/stages', data),
        get: id => axios.get(`/librarium/api/v1/sys/timezones/stages/${id}`, { cache: true }),
        put: (id, data) => axios.put(`/librarium/api/v1/sys/timezones/stages/${id}`, data),
        delete: id => axios.delete(`/librarium/api/v1/sys/timezones/stages/${id}`),
      },
    },
    operator: {
      list: (page = 1, per_page = 10, order_by = null, options) => axios.get('/librarium/api/v1/sys/operators', {
        params: { page, per_page, order_by, ...options },
      }),
      search: (page = 1, per_page = 10, order_by = null, q, options) => axios.get('/librarium/api/v1/sys/operators/search', {
        params: { page, per_page, order_by, q, ...options },
      }),
      get: id => axios.get(`/librarium/api/v1/sys/operators/${id}`, { cache: true }),
      get_stages: code => axios.get(`/librarium/api/v1/sys/operators/${code}/stages`, { cache: true }),
      stage: {
        post: data => axios.post('/librarium/api/v1/sys/operators/stages', data),
        get: id => axios.get(`/librarium/api/v1/sys/operators/stages/${id}`, { cache: true }),
        put: (id, data) => axios.put(`/librarium/api/v1/sys/operators/stages/${id}`, data),
        delete: id => axios.delete(`/librarium/api/v1/sys/operators/stages/${id}`),
      },
    },
    mobile_network: {
      list: (page = 1, per_page = 10, order_by = null, options) => axios.get('/librarium/api/v1/sys/mobilenetworks', {
        params: { page, per_page, order_by, ...options },
      }),
      search: (page = 1, per_page = 10, order_by = null, q, options) => axios.get('/librarium/api/v1/sys/mobilenetworks/search', {
        params: { page, per_page, order_by, q, ...options },
      }),
      get: id => axios.get(`/librarium/api/v1/sys/mobilenetworks/${id}`, { cache: true }),
      get_stages: code => axios.get(`/librarium/api/v1/sys/mobilenetworks/${code}/stages`, { cache: true }),
      stage: {
        post: data => axios.post('/librarium/api/v1/sys/mobilenetworks/stages', data),
        get: id => axios.get(`/librarium/api/v1/sys/mobilenetworks/stages/${id}`, { cache: true }),
        put: (id, data) => axios.put(`/librarium/api/v1/sys/mobilenetworks/stages/${id}`, data),
        delete: id => axios.delete(`/librarium/api/v1/sys/mobilenetworks/stages/${id}`),
      },
    },
    currency: {
      list: (page = 1, per_page = 10, order_by = null, options) => axios.get('/librarium/api/v1/sys/currencies', {
        params: { page, per_page, order_by, ...options },
      }),
      search: (page = 1, per_page = 10, order_by = null, q, options) => axios.get('/librarium/api/v1/sys/currencies/search', {
        params: { page, per_page, order_by, q, ...options },
      }),
      get: code => axios.get(`/librarium/api/v1/sys/currencies/${code}`, { cache: true }),
      get_stages: code => axios.get(`/librarium/api/v1/sys/currencies/${code}/stages`, { cache: true }),
      stage: {
        post: data => axios.post('/librarium/api/v1/sys/currencies/stages', data),
        get: id => axios.get(`/librarium/api/v1/sys/currencies/stages/${id}`, { cache: true }),
        put: (id, data) => axios.put(`/librarium/api/v1/sys/currencies/stages/${id}`, data),
        delete: id => axios.delete(`/librarium/api/v1/sys/currencies/stages/${id}`),
      },
    },
    employee: {
      list: (page = 1, per_page = 10, order_by = null, options) => axios.get('/librarium/api/v1/staff/employees', {
        params: { page, per_page, order_by, ...options },
      }),
      search: (page = 1, per_page = 10, order_by = null, q, options) => axios.get('/librarium/api/v1/staff/employees/search', {
        params: { page, per_page, order_by, q, ...options },
      }),
      get: id => axios.get(`/librarium/api/v1/staff/employees/${id}`, { cache: true }),
      get_stages: code => axios.get(`/librarium/api/v1/staff/employees/${code}/stages`, { cache: true }),
      stage: {
        post: data => axios.post('/librarium/api/v1/staff/employees/stages', data),
        get: id => axios.get(`/librarium/api/v1/staff/employees/stages/${id}`, { cache: true }),
        put: (id, data) => axios.put(`/librarium/api/v1/staff/employees/stages/${id}`, data),
        delete: id => axios.delete(`/librarium/api/v1/staff/employees/stages/${id}`),
      },
    },
    viber_rate: {
      list: (page = 1, per_page = 10, order_by = null, options) => axios.get('/librarium/api/v1/viber_rates', {
        params: { page, per_page, order_by, ...options },
      }),
      search: (page = 1, per_page = 10, order_by = null, q, options) => axios.get('/librarium/api/v1/viber_rates/search', {
        params: { page, per_page, order_by, q, ...options },
      }),
      post: data => axios.post('/librarium/api/v1/viber_rates/stages', data),
      get: id => axios.get(`/librarium/api/v1/viber_rates/${id}`, { cache: true }),
      get_stages: code => axios.get(`/librarium/api/v1/viber_rates/${code}/stages`, { cache: true }),
      put: (id, data) => axios.put(`/librarium/api/v1/viber_rates/stages/${id}`, data),
      delete: id => axios.delete(`/librarium/api/v1/viber_rates/stages/${id}`),
    },
    bulk_rate: {
      list: (page = 1, per_page = 10, order_by = null, options) => axios.get('/librarium/api/v1/msgio/bulk_rates', {
        params: { page, per_page, order_by, ...options },
      }),
      search: (page = 1, per_page = 10, order_by = null, q, options) => axios.get('/librarium/api/v1/msgio/bulk_rates/search', {
        params: { page, per_page, order_by, q, ...options },
      }),
      get: id => axios.get(`/librarium/api/v1/msgio/bulk_rates/${id}`, { cache: true }),
      get_stages: code => axios.get(`/librarium/api/v1/msgio/bulk_rates/${code}/stages`, { cache: true }),
      stage: {
        post: data => axios.post('/librarium/api/v1/msgio/bulk_rates/stages', data),
        get: stage_id => axios.get(`/librarium/api/v1/msgio/bulk_rates/stages/${stage_id}`, { cache: true }),
        put: (id, data) => axios.put(`/librarium/api/v1/msgio/bulk_rates/stages/${id}`, data),
        delete: id => axios.delete(`/librarium/api/v1/msgio/bulk_rates/stages/${id}`),
      },
    },
    holiday: {
      list: (page = 1, per_page = 10, order_by = null, options) => axios.get('/librarium/api/v1/cportal/holidays', {
        params: { page, per_page, order_by, ...options },
      }),
    },
  },
  notes: {
    note: {
      list: (page = 1, page_size = 10, order_by = null, options) => axios.get('/notes/api/v1/notes', {
        params: { page, page_size, order_by, ...options },
      }),
      post: data => axios.post('/notes/api/v1/notes', data),
      get: id => axios.get(`/notes/api/v1/notes/${id}`),
      put: (id, data) => axios.put(`/notes/api/v1/notes/${id}`, data),
      delete: id => axios.delete(`/notes/api/v1/notes/${id}`),
    },
  },
  import_manager: {
    source: {
      list: (page = 1, page_size = 10, order_by = null, options) => axios.get('/import-manager/api/v1/sources', {
        params: { page, page_size, order_by, ...options },
      }),
      statuses: () => axios.get('/import-manager/api/v1/sources/statuses'),
      post: data => axios.post('/import-manager/api/v1/sources', data),
      get: id => axios.get(`/import-manager/api/v1/sources/${id}`),
      put: (id, data) => axios.put(`/import-manager/api/v1/sources/${id}`, data),
      delete: id => axios.delete(`/import-manager/api/v1/sources/${id}`),
      status: {
        put: (id, data) => axios.put(`/import-manager/api/v1/sources/${id}/statuses`, data),
      },
    },
    job: {
      list: (page = 1, page_size = 10, order_by = null, options) => axios.get('/import-manager/api/v1/jobs', {
        params: { page, page_size, order_by, ...options },
      }),
      statuses: () => axios.get('/import-manager/api/v1/jobs/statuses'),
      post: data => axios.post('/import-manager/api/v1/jobs', data),
      get: id => axios.get(`/import-manager/api/v1/jobs/${id}`),
      put: (id, data) => axios.put(`/import-manager/api/v1/jobs/${id}`, data),
      delete: id => axios.delete(`/import-manager/api/v1/jobs/${id}`),
      status: {
        put: (id, data) => axios.put(`/import-manager/api/v1/jobs/${id}/statuses`, data),
      },
      start: id => axios.post(`/import-manager/api/v1/jobs/${id}/start`),
      restart: id => axios.post(`/import-manager/api/v1/jobs/${id}/restart`),
      type: () => axios.get('/import-manager/api/v1/jobs/-/types'),
    },
    container: {
      list: (page = 1, page_size = 10, order_by = null, options) => axios.get('/import-manager/api/v1/containers', {
        params: { page, page_size, order_by, ...options },
      }),
      statuses: () => axios.get('/import-manager/api/v1/containers/statuses'),
      post: data => axios.post('/import-manager/api/v1/containers', data),
      get: id => axios.get(`/import-manager/api/v1/containers/${id}`),
      put: (id, data) => axios.put(`/import-manager/api/v1/containers/${id}`, data),
      delete: id => axios.delete(`/import-manager/api/v1/containers/${id}`),
      status: {
        put: (id, data) => axios.put(`/import-manager/api/v1/containers/${id}/statuses`, data),
      },
      import: {
        partial: id => axios.post(`/import-manager/api/v1/containers/${id}/import/partial`),
        reimport: id => axios.post(`/import-manager/api/v1/containers/${id}/reimport`),
        default: id => axios.post(`/import-manager/api/v1/containers/${id}/import`),
      },
    },
  },
  widgets: {
    widget: {
      statistic_cost: () => axios.get('/widgets/api/v1/widgets/statistic-cost'),
    },
  },
  trailer: {
    settings: {
      list: (page = 1, page_size = 10, order_by = null, options) => axios.get('/trailer/api/v1/settings', {
        params: { page, page_size, order_by, ...options },
      }),
      post: data => axios.post('/trailer/api/v1/settings', data),
      get: id => axios.get(`/trailer/api/v1/settings/${id}`),
      put: (id, data) => axios.put(`/trailer/api/v1/settings/${id}`, data),
      delete: id => axios.delete(`/trailer/api/v1/settings/${id}`),
      group: {
        store: data => axios.post('/trailer/api/v1/settings/group', data),
      },
      slim: {
        get: (entity_id, key) => axios.get(`/trailer/api/v1/settings/slim/entity-id:${entity_id}/key:${key}`),
        store: (entity_id, key, data) => axios.post(`/trailer/api/v1/settings/slim/entity-id:${entity_id}/key:${key}`, data),
        delete: (entity_id, key) => axios.delete(`/trailer/api/v1/settings/slim/entity-id:${entity_id}/key:${key}`),
      },
    },
  },
  image: {
    image: {
      restrictions: {
        list: (page = 1, page_size = 10, order_by = null, options) => axios.get('/images/api/v1/restrictions', {
          params: { page, page_size, order_by, ...options },
        }),
      },
      list: (page = 1, page_size = 10, order_by = null, options) => axios.get('/images/api/v1/images', {
        params: { page, page_size, order_by, ...options },
      }),
      post: data => axios.post('/images/api/v1/images', data),
      delete: id => axios.delete(`/images/api/v1/images/${id}`),
    },
  },
  testphone: {
    phones: {
      verification: {
        post: data => axios.post('/testphone/api/v1/phones/verification', data),
        code: data => axios.post('/testphone/api/v1/phones/verification/code', data),
        rcs: data => axios.post('/testphone/api/v1/phones/verification/rcs', data),
      },
      list: (page = 1, page_size = 10, order_by = null, options) => axios.get('/testphone/api/v1/phones', {
        params: { page, page_size, order_by, ...options },
      }),
      get: id => axios.get(`/testphone/api/v1/phones/${id}`),
      delete: id => axios.delete(`/testphone/api/v1/phones/${id}`),
      personalise: (id, data) => axios.put(`/testphone/api/v1/phones/${id}/personalise`, data),
    },
    dispatch: {
      send: data => axios.post('/testphone/api/v1/dispatch/send', data),
    },
  },
  oauth2_app: {
    client: {
      list: (page = 1, page_size = 10, order_by = null, options) => axios.get('/oauth2-app/api/v1/clients', {
        params: { page, page_size, order_by, ...options },
      }),
      post: data => axios.post('/oauth2-app/api/v1/clients', data),
      get: id => axios.get(`/oauth2-app/api/v1/clients/${id}`),
      put: (id, data) => axios.put(`/oauth2-app/api/v1/clients/${id}`, data),
      delete: id => axios.delete(`/oauth2-app/api/v1/clients/${id}`),
      users: (id, page = 1, page_size = 10, order_by = null, options) => axios.get(`/oauth2-app/api/v1/clients/${id}/users`, {
        params: { page, page_size, order_by, ...options },
      }),
      status: {
        list: () => axios.get('/oauth2-app/api/v1/clients/statuses'),
        put: (id, data) => axios.put(`/oauth2-app/api/v1/clients/${id}/status`, data),
      },
      acceess: {
        list: (page = 1, page_size = 10, order_by = null, options) => axios.get('/oauth2-app/api/v1/clients/acceess', {
          params: { page, page_size, order_by, ...options },
        }),
        delete: id => axios.delete(`/oauth2-app/api/v1/clients/acceess/${id}`),
      },
    },
  },
  scheduler: {
    task: {
      list: (page = 1, page_size = 10, order_by = null, options) => axios.get('/documents-scheduler/api/v1/tasks', {
        params: { page, page_size, order_by, ...options },
      }),
      post: data => axios.post('/documents-scheduler/api/v1/tasks', data),
      get: id => axios.get(`/documents-scheduler/api/v1/tasks/${id}`),
      put: (id, data) => axios.put(`/documents-scheduler/api/v1/tasks/${id}`, data),
      delete: id => axios.delete(`/documents-scheduler/api/v1/tasks/${id}`),
      activate: id => axios.get(`/documents-scheduler/api/v1/tasks/${id}/activate`),
      cancel: id => axios.get(`/documents-scheduler/api/v1/tasks/${id}/cancel`),
    },
    template: {
      list: (page = 1, page_size = 10, order_by = null, options) => axios.get('/documents-scheduler/api/v1/templates', {
        params: { page, page_size, order_by, ...options },
      }),
      post: data => axios.post('/documents-scheduler/api/v1/templates', data),
      get: id => axios.get(`/documents-scheduler/api/v1/templates/${id}`),
      put: (id, data) => axios.put(`/documents-scheduler/api/v1/templates/${id}`, data),
      delete: id => axios.delete(`/documents-scheduler/api/v1/templates/${id}`),
      launchADispatch: {
        list: () => axios.get('/documents-scheduler/api/v1/templates/launch-a-dispatch'),
        quickTask: {
          post: data => axios.post('/documents-scheduler/api/v1/templates/launch-a-dispatch/quick-task', data),
        },
      },
      clientSenderStatusActive: {
        list: () => axios.get('/documents-scheduler/api/v1/templates/client-sender-status-active'),
        quickTask: {
          post: data => axios.post('/documents-scheduler/api/v1/templates/client-sender-status-active/quick-task', data),
        },
      },
      clientSenderStatusDeact: {
        list: () => axios.get('/documents-scheduler/api/v1/templates/client-sender-status-deact'),
        quickTask: {
          post: data => axios.post('/documents-scheduler/api/v1/templates/client-sender-status-deact/quick-task', data),
        },
      },
    },
    operation: {
      list: (page = 1, page_size = 10, order_by = null, options) => axios.get('/documents-scheduler/api/v1/operations', {
        params: { page, page_size, order_by, ...options },
      }),
      post: data => axios.post('/documents-scheduler/api/v1/operations', data),
      get: id => axios.get(`/documents-scheduler/api/v1/operations/${id}`),
      put: (id, data) => axios.put(`/documents-scheduler/api/v1/operations/${id}`, data),
      delete: id => axios.delete(`/documents-scheduler/api/v1/operations/${id}`),
    },
    type: {
      list: (page = 1, page_size = 10, order_by = null, options) => axios.get('/documents-scheduler/api/v1/types', {
        params: { page, page_size, order_by, ...options },
      }),
      post: data => axios.post('/documents-scheduler/api/v1/types', data),
      get: id => axios.get(`/documents-scheduler/api/v1/types/${id}`),
      put: (id, data) => axios.put(`/documents-scheduler/api/v1/types/${id}`, data),
      delete: id => axios.delete(`/documents-scheduler/api/v1/types/${id}`),
    },
  },
  enoti: {
    event: {
      list: (page = 1, page_size = 10, order_by = null, options) => axios.get('/enoti/api/v1/events', {
        params: { page, page_size, order_by, ...options },
      }),
      get: id => axios.get(`/enoti/api/v1/events/${id}`),
    },
    action: {
      list: (page = 1, page_size = 10, order_by = null, options) => axios.get('/enoti/api/v1/actions', {
        params: { page, page_size, order_by, ...options },
      }),
      post: data => axios.post('/enoti/api/v1/actions', data),
      get: id => axios.get(`/enoti/api/v1/actions/${id}`),
      put: (id, data) => axios.put(`/enoti/api/v1/actions/${id}`, data),
      delete: id => axios.delete(`/enoti/api/v1/actions/${id}`),
      tag: {
        sub: {
          post: (tagName, data) => axios.post(`/enoti/api/v1/actions/tags/${tagName}/subs`, data),
        },
        unsub: {
          post: (tagName, data) => axios.post(`/enoti/api/v1/actions/tags/${tagName}/subs/unsub`, data),
        },
      },
      sub: {
        post: (id, data) => axios.post(`/enoti/api/v1/actions/${id}/subs`, data),
        get: id => axios.get(`/enoti/api/v1/actions/${id}/subs`),
      },
    },
    template: {
      list: (page = 1, page_size = 10, order_by = null, options) => axios.get('/enoti/api/v1/templates', {
        params: { page, page_size, order_by, ...options },
      }),
      post: data => axios.post('/enoti/api/v1/templates', data),
      get: id => axios.get(`/enoti/api/v1/templates/${id}`),
      put: (id, data) => axios.put(`/enoti/api/v1/templates/${id}`, data),
      delete: id => axios.delete(`/enoti/api/v1/templates/${id}`),
    },
    sub: {
      list: (page = 1, page_size = 10, order_by = null, options) => axios.get('/enoti/api/v1/subs', {
        params: { page, page_size, order_by, ...options },
      }),
      get: id => axios.get(`/enoti/api/v1/subs/${id}`),
      put: (id, data) => axios.put(`/enoti/api/v1/subs/${id}`, data),
      delete: id => axios.delete(`/enoti/api/v1/subs/${id}`),
      changeLang: data => axios.post('/enoti/api/v1/subs/changeLang', data),
      changePhone: data => axios.post('/enoti/api/v1/subs/changePhone', data),
    },
  },
  support: {
    send: {
      support: data => axios.post('/support/api/v1/send/support', data),
    },
  },
  saga: {
    balance: {
      get: () => axios.get('/api-gateway/api/v1/balances'),
    },
    contract: {
      list: (page = 1, page_size = 10, order_by = null, options) => axios.get('/api-gateway/api/v1/contracts', {
        params: { page, page_size, order_by, ...options },
      }),
      get: id => axios.get(`/api-gateway/api/v1/contracts/${id}`),
      service: {
        list: (contract_id, page = 1, page_size = 10, order_by = null, timestamp, options) => axios.get(`/api-gateway/api/v1/contracts/${contract_id}/services`, {
          params: { page, page_size, order_by, timestamp, ...options },
          cache: true,
        }),
        tariff: {
          list: (contract_id, service_id, page = 1, page_size = 10, options) => axios.get(`/api-gateway/api/v1/contracts/${contract_id}/services/${service_id}/tariffs`, {
            params: { page, page_size, ...options },
          }),
        },
      },
      payment: {
        list: (id, page = 1, page_size = 10, order_by = null, options) => axios.get(`/api-gateway/api/v1/contracts/${id}/payments`, {
          params: { page, page_size, order_by, ...options },
        }),
      },
      recurrentOptions: (id, data) => axios.put(`/api-gateway/api/v1/contracts/${id}/recurrent_options`, data),
    },
    dispatch: {
      post: data => axios.post('/api-gateway/api/v1/dispatch', data),
      ab: {
        post: data => axios.post('/api-gateway/api/v1/dispatch/ab', data),
        progress: event_id => axios.get(`/api-gateway/api/v1/dispatch/ab/${event_id}/progress`),
      },
      prepare: {
        post: (id, params) => axios.post(`/api-gateway/api/v1/dispatch/${id}/prepare`, {}, { params }),
        progress: id => axios.get(`/api-gateway/api/v1/dispatch/${id}/prepare/progress`),
      },
      start: id => axios.post(`/api-gateway/api/v1/dispatch/${id}/start`),
      abort: id => axios.post(`/api-gateway/api/v1/dispatch/${id}/abort`),
      price: (data, config = {}) => axios.post('/api-gateway/api/v1/dispatch/price', data, config),
    },
    template: {
      post: data => axios.post('/api-gateway/api/v1/templates', data),
      put: (id, data) => axios.put(`/api-gateway/api/v1/templates/${id}`, data),
      copy: id => axios.post(`/api-gateway/api/v1/templates/${id}/copy`),
      delete: id => axios.delete(`/api-gateway/api/v1/templates/${id}`),
      status: {
        moderation: id => axios.post(`/api-gateway/api/v1/templates/${id}/status/moderation`),
      },
    },
    handler: {
      post: data => axios.post('/api-gateway/api/v1/handlers', data),
    },
    drive: {
      file: {
        upload: (category, data, onUploadProgress) => axios.post(`/api-gateway/api/v1/drive/upload/files/${category}`, data, { onUploadProgress }),
      },
    },
    clientsender: {
      moderation: id => axios.post(`/api-gateway/api/v1/clientsenders/${id}/moderation`),
    },
    bots: {
      telegram: {
        post: data => axios.post('/api-gateway/api/v1/bots/telegram', data),
      },
    },
    playground: {
      post: () => axios.post('/api-gateway/api/v1/playground'),
    },
  },
  drive: {
    file: {
      list: (category, page = 1, limit = 10, order_by = null, options) => axios.get(`/drive/api/v1/files/${category}`, {
        params: { skip: (page - 1) * limit, limit, order_by, ...options },
      }),
      get: (category, shortHash, name) => axios.get(`/drive/api/v1/files/${category}/${shortHash}/${name}`),
      upload: (category, data, onUploadProgress) => axios.post(`/drive/api/v1/upload/files/${category}`, data, { onUploadProgress }),
      edit: (category, shortHash, name, meta) => axios.patch(`/drive/api/v1/files/${category}/${shortHash}/${name}`, {}, { params: { meta: JSON.stringify(meta) } }),
      download: (category, shortHash, name, onDownloadProgress) => axios.get(`/drive/api/v1/files/${category}/${shortHash}/${name}`, {
        params: { alt: 'media' },
        responseType: 'blob',
        onDownloadProgress,
      }),
      delete: (category, shortHash, name) => axios.delete(`/drive/api/v1/files/${category}/${shortHash}/${name}`),
      stats: (userType, ID) => axios.get(`/drive/api/v1/files/stats/${userType}/${ID}`),
    },
  },
  chat: {
    events: {
      auth: () => axios.get('/chats/api/v1/events/auth'),
    },
    chat: {
      list: (page = 1, page_size = 10, _order_by = null, options) => axios.get('/chats/api/v1/chats', {
        params: { page, page_size, /* order_by, */ ...options },
      }),
      search: (page = 1, page_size = 10, _order_by = null, options) => axios.get('/chats/api/v1/chats/search', {
        params: { page, page_size, /* order_by, */ ...options },
      }),
      get: (id, cache = false) => axios.get(`/chats/api/v1/chats/${id}`, { cache }),
      exportAll: params => axios.get('/chats/api/v1/chats/export', { params }),
      export: (id, params) => axios.get(`/chats/api/v1/chats/${id}/export`, { params }),
      start: data => axios.post('/chats/api/v1/chats/new', data),
      counters: params => axios.get('/chats/api/v1/chats/counters', { params }),
      status: (id, status) => axios.put(`/chats/api/v1/chats/${id}/status`, {}, { params: { status } }),
      assign: (id, user_id) => axios.put(`/chats/api/v1/chats/${id}/assign/${user_id}`),
      setting: {
        get: (cache = false) => axios.get('/chats/api/v1/chats/settings', { cache }),
        edit: data => axios.put('/chats/api/v1/chats/settings', data),
      },
    },
    messages: {
      history: (chat_id, start_message_id, offset, count = 10, options) => axios.get(`/chats/api/v1/messages/${chat_id}/history`, {
        params: { start_message_id, offset, count, ...options },
      }),
    },
    user: {
      status: {
        get: () => axios.get('/chats/api/v1/user/status'),
        put: status => axios.put('/chats/api/v1/user/status', { status }),
      },
      ping: () => axios.post('/chats/api/v1/user/ping'),
    },
  },
  profile: {
    put: (_id, data) => axios.put('/entities/api/v1/users/-/profile', data),
    passwordPut: data => axios.post(
      'entities/api/v1/users/password/update',
      data,
    ),
  },
  short_link: {
    domain: {
      list: (page = 1, page_size = 10, order_by = null, options) => axios.get('/short-links/api/v1/domain', {
        params: { page, page_size, order_by, ...options },
      }),
      get: id => axios.get(`/short-links/api/v1/domain/${id}`),
      post: data => axios.post('/short-links/api/v1/domain', data),
      put: (id, data) => axios.put(`/short-links/api/v1/domain/${id}`, data),
      delete: id => axios.delete(`/short-links/api/v1/domain/${id}`),
    },
    link: {
      list: (page = 1, page_size = 10, order_by = null, options) => axios.get('/short-links/api/v1/link', {
        params: { page, page_size, order_by, ...options },
      }),
      get: id => axios.get(`/short-links/api/v1/link/${id}`),
      post: data => axios.post('/short-links/api/v1/link', data),
      put: (id, data) => axios.put(`/short-links/api/v1/link/${id}`, data),
      delete: id => axios.delete(`/short-links/api/v1/link/${id}`),
      statistic: (id, params) => axios.get(`/short-links/api/v1/link/${id}/statistic`, { params }),
    },
  },
  roles,
};
