export default {
  title: 'Пополнить баланс',
  select_contract: 'Выберите договор для пополнения',
  agreement_since: 'Договор от',
  auto_payment_enabled: 'Включен автоплатеж',
  contract_id_placeholder: 'Выберите...',
  btn_checkout: 'Перейти к оплате',
  helper: 'Вы будете перенаправлены на store.messaggio.by для завершения оплаты.',
  errors_amount_min_value_RUB: 'Сумма платежа должна быть не менее {amount} рублей',
  errors_amount_min_value_UAH: 'Сумма платежа должна быть не менее {amount} UAH',
  cid: {
    errors: {
      required: 'Пожалуйста, выберите договор для пополнения',
    },
  },
  amount: {
    errors: {
      required: 'Пожалуйста, укажите сумму для пополнения',
    },
  },
}
