import type { Plugin, Context } from '@nuxt/types';
import axios, { AxiosAdapter } from 'axios';
import axiosCancel from 'axios-cancel';
import { Store } from 'vuex';
import { RootState } from '~/store';
import {
  cacheAdapterEnhancer,
  throttleAdapterEnhancer,
} from 'axios-extensions';
import { AxiosError } from 'axios';

let authorizing = false;

const plugin: Plugin = ({
  store,
  redirect,
  route,
}: Omit<Context, 'store'> & { store: Store<RootState> }) => {
  axios.defaults.baseURL = process.env.API_HOST || '/';
  // axios.defaults.withCredentials = true;
  axios.defaults.adapter = throttleAdapterEnhancer(
    cacheAdapterEnhancer(axios.defaults.adapter as AxiosAdapter, {
      enabledByDefault: false,
    }),
    { threshold: 2 * 1000 }
  );

  axiosCancel(axios);

  axios.interceptors.request.use((config) => {
    if (store.state.auth.token) {
      config.headers.Authorization = 'Bearer ' + store.state.auth.token;
    }

    return config;
  });

  axios.interceptors.response.use(
    (response) => {
      if (response?.data?.error === 'one time password is required') {
        throw new Error('MESSAGGIO_OTP');
      }
      return response;
    },
    async (error: AxiosError) => {
      if (axios.isCancel(error) || !error?.response?.status) {
        return Promise.reject(error);
      }

      switch (error.response.status) {
        case 401: {
          if (!store.state.auth.credentials) {
            await store.dispatch('auth/reset');

            localStorage.removeItem('lk-messaggio');

            return redirect(
              `/permission/login?redirect=${encodeURIComponent(route.fullPath)}`
            );
          }

          if (authorizing) {
            await new Promise<void>((resolve) => {
              const interval = setInterval(() => {
                if (!authorizing) {
                  clearInterval(interval);
                  resolve();
                }
              }, 50);
            });

            return await axios.request(error.config);
          }

          try {
            authorizing = true;
            const credentials = JSON.parse(atob(store.state.auth.credentials));

            const result = await store.dispatch('auth/login', credentials);

            if (!result?.success) {
              await store.dispatch('auth/reset');

              localStorage.removeItem('lk-messaggio');

              return redirect(
                `/permission/login?redirect=${encodeURIComponent(
                  route.fullPath
                )}`
              );
            }

            authorizing = false;

            return await axios.request(error.config);
          } catch (e) {}

          await store.dispatch('auth/reset');

          localStorage.removeItem('lk-messaggio');

          return redirect(
            `/permission/login?redirect=${encodeURIComponent(route.fullPath)}`
          );
        }
      }

      return Promise.reject(error);
    }
  );
};

export default plugin;
