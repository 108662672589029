import { ActionTree, GetterTree, MutationTree } from 'vuex';
import api from '~/api';
import ReLogin from '~/api/relogin';
import ObserverApi from '~/utils/ObserverApi';
import { getDirectoryCountryOnePromises } from '~/store/directory/country';
import { resetAuthToken, setAuthToken } from '~/utils/auth';
import type { RootState } from '~/store';
import { updateOptions } from '~/plugins/tracker/tracker';
import { globalUserPropertiesReset } from '~/plugins/tracker/amplitude';

export const AUTH_COOKIE = 'x-access-token-my';

export type State = {
  user?: ResUser;
  token?: string;
  credentials?: string;
  accessLevel?: {
    id: string;
    name: string;
    updated_at: string;
    created_at: string;
  };
  tenant?: Record<string, unknown>;
  global?: Record<string, unknown>;
};

export type ResUser = {
  id: string;
  created_at: number;
  email: string;
  first_name: string;
  last_name: string;
  namespace: string;
  phone: string;
  second_name: string;
  status: ResUserStatus;
  updated_at: number;
  verified: boolean;
};

export type ResUserStatus = {
  id: string;
  name: string;
  description: string;
};

export const state = (): State => ({});

export const getters: GetterTree<State, RootState> = {
  isOnboardingDone: (state) =>
    !state.global || state?.global['global.onboarding.done'] !== undefined,
  isUserFresh: (state) =>
    state.user && state.user.created_at + 30 * 24 * 3600 > Date.now() / 1000,
};

export const mutations: MutationTree<State> = {
  setUser(store, user: ResUser) {
    store.user = user;
    updateOptions(user, store.tenant);
  },
  setToken(store, token?: string) {
    store.token = token;
  },
  setCredentials(store, credentialsBase64?: string) {
    store.credentials = credentialsBase64;
  },
  resetUser(store) {
    store.user = undefined;
  },
  setAccessLevel(store, accessLevel) {
    store.accessLevel = accessLevel;
  },
  setUserPhone(store, { phone }: { phone: string }) {
    store.user = { ...(store.user as ResUser), phone };
  },
  setTenant(store, tenant) {
    store.tenant = tenant;
    updateOptions(store.user, tenant);
  },
  setGlobal(store, global) {
    store.global = global;
  },
  reset(store) {
    const defaultState = state();

    for (const key in store) {
      store[key] = defaultState[key];
    }

    // новый фронт
    localStorage.removeItem('auth');
    localStorage.removeItem('settings');
  },
};

export const actions: ActionTree<State, RootState> = {
  async fetch({ commit, dispatch, state }) {
    return Promise.all([
      api.auth.me().then(({ data }) => (commit('setUser', data), data)),
      dispatch('fetchTenant'),
      api.roles.users
        .access_level()
        .then(({ data }) => commit('setAccessLevel', data)),
    ])
      .then(async () => {
        const { user, tenant } = state;
        updateOptions(user, tenant);
        await Promise.all([dispatch('fetchSettingGlobal')]);
        return user;
      })
      .catch((error) => {
        console.error('api.auth.me | error = ', error.toString());
        commit('resetUser', {});
        throw error;
      });
  },
  fetchTenant({ commit, dispatch }) {
    const observer = new ObserverApi(dispatch);
    return api.entities.tenant.profile
      .get()
      .then(async (response) => {
        response.data = await getDirectoryCountryOnePromises(
          observer,
          response.data,
          'location_country'
        );
        commit('setTenant', response.data);
        return response.data;
      })
      .catch((error) => {
        console.error('api.entities.tenant.profile.get | error = ', error);
        throw error;
      });
  },
  fetchSettingGlobal({ state, dispatch, commit }) {
    return dispatch(
      'trailer/setting/list',
      { entity_id: state.tenant.id, key: 'global.*', pagesize: 99999 },
      { root: true }
    ).then(({ items } = {}) => {
      const global = items.reduce(
        (r, item) => ({ ...r, [item.key]: item.value }),
        {}
      );
      commit('setGlobal', global);
      return global;
    });
  },
  async login(
    { commit, dispatch },
    data: {
      email: string;
      password: string;
      remember: boolean;
      token: string;
      confirmation: string;
    }
  ) {
    const { email, password } = data;
    const response = await api.auth.login(data);

    if (typeof response.data != 'object') return;

    const credentials = btoa(
      JSON.stringify({
        email: data.email,
        password: data.password,
      })
    );
    commit('setCredentials', credentials);

    const { token } = response.data.success;
    commit('setToken', token);
    setAuthToken(token);

    await dispatch('afterLogReg', {
      email,
      password,
      token: response.data.success.token,
    });
  },
  async registration(
    { commit, dispatch },
    value: { tenant; user; token; lang; utm }
  ) {
    const { email, password } = value.user;
    const dataApi = {
      email,
      password,
      lang: value.lang,
      company_name: value.tenant.name,
      token: value.token,
      utm: value.utm,
    };

    const response = await api.auth.registration(dataApi);

    if (typeof response.data != 'object') return;

    const credentials = btoa(
      JSON.stringify({
        email: dataApi.email,
        password: dataApi.password,
      })
    );
    commit('setCredentials', credentials);

    const { token } = response.data;
    commit('setToken', token);
    setAuthToken(token);

    await dispatch('afterLogReg', {
      email,
      password,
      token: response.data.token,
    });
  },
  completed({ dispatch }, { email, password, hash }) {
    return api.auth
      .completed({ email, password, hash })
      .then(() => dispatch('login', { email, password }));
  },
  afterLogReg({ dispatch }, { email, password, token }) {
    passwordEncrypt(email, password);
    return dispatch('token', token);
  },
  reset({ commit }) {
    localStorage.removeItem('x-access-token-my-relogin');
    commit('reset');
    resetAuthToken();
    globalUserPropertiesReset();
  },
  async token({ commit, dispatch }, token) {
    setAuthToken(token);
    commit('setToken', token);
    return await dispatch('fetch', {});
  },
  passwordRecovery(_, { email }) {
    return api.auth.password.restore({ email }).then(({ data }) => data);
  },
  passwordRecoverySet({ dispatch }, data) {
    return api.auth.password
      .set(data)
      .then((res) => dispatch('token', res.data.success.token))
      .then((dataMe) => {
        // console.info('passwordRecoverySet | data = ', data);
        passwordEncrypt(data.email, data.new_password);
        return dataMe;
      });
  },
  verifyEmail({ dispatch }, { token }) {
    return api.auth
      .verifyEmail({ token })
      .then(({ data }) => dispatch('token', data.success.token));
  },
};

const passwordEncrypt = (email, password) =>
  new ReLogin(false).encrypt({ email, password });
