export default {
  progress: {
    title: 'Додавання тестового номера',
  },
  left: {
    title: 'Додайте номер телефону для прийому тестових повідомлень.',
    subtitle: 'Після додавання номера вам надійде телефонний дзвінок, останні 4 цифри номера якого – код підтвердження. Або надійде смс з кодом підтвердження.',
    subsubtitle: 'Ви можете використовувати свій номер телефону для прийому тестових повідомлень, також ви зможете додати додаткові тестові номери в налаштуваннях облікового запису.',
  },
  form_code: {
    success: {
      text: 'Номер телефону підтверджено. Для продовження натисніть "Готово"',
    },
  },
  submit: {
    progress: {
      title: 'Настроюємо ваш кабінет',
    },
  },
};
