export default {
  title_browser: 'Вхід',
  title: 'Авторизація',
  oauth_loading: 'OAuth вхід...',
  email_placeholder: 'Email адреса',
  password_placeholder: 'Пароль',
  forgot_password: 'Забули пароль?',
  confirmation: 'Google Auth код',
  confirmation_placeholder: 'Google Auth код',
  sign_in_btn: 'Увійти',
  or_sign_in_with: 'або увійдіть за допомогою',
  oa2_google: 'Google',
  oa2_facebook: 'Facebook',
  oa2_yandex: 'Яндекс',
  by_continuing: 'Продовжуючи, ви підтверджуєте, що прочитали та згодні з <a href="https://messaggio.by/terms-and-conditions/" target="_blank">Угодою користувача</a>, <a href="https://messaggio.by/privacy-policy/" target="_blank">Політикою конфіденційності</a> та <a href="https://messaggio.by/terms-and-conditions/contract-for-the-provision-of-messaging-services-offer/" target="_blank">Офертою</a>',
  error_max_rate_limiting_reached: 'Досягнуто ліміт спроб, спробуйте через деякий час.',
  error_tenant_is_block: 'Будь ласка, зверніться до служби підтримки: <a href="mailto:support@messaggio.com">support@messaggio.com</a>',

  error403: {
    title: 'Помилка авторизації',
    description: 'Логін або пароль вказано невірно.',
  },
};
