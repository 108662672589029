import { documentsSchedulerFetch } from "./documentsSchedulerFetcher.js";
const getTypes = (variables, signal) => documentsSchedulerFetch({ url: "/api/v1/type", method: "get", ...variables, signal });
const getType = (variables, signal) => documentsSchedulerFetch({ url: "/api/v1/type/{typeID}", method: "get", ...variables, signal });
const getOperations = (variables, signal) => documentsSchedulerFetch({ url: "/api/v1/operation", method: "get", ...variables, signal });
const getOperation = (variables, signal) => documentsSchedulerFetch({
  url: "/api/v1/operation/{operationID}",
  method: "get",
  ...variables,
  signal
});
const getTemplates = (variables, signal) => documentsSchedulerFetch({ url: "/api/v1/template", method: "get", ...variables, signal });
const getTemplate = (variables, signal) => documentsSchedulerFetch({
  url: "/api/v1/templates/{templateID}",
  method: "get",
  ...variables,
  signal
});
const getTasks = (variables, signal) => documentsSchedulerFetch({ url: "/api/v1/task", method: "get", ...variables, signal });
const getTask = (variables, signal) => documentsSchedulerFetch({ url: "/api/v1/task/{taskID}", method: "get", ...variables, signal });
const activateTask = (variables, signal) => documentsSchedulerFetch({
  url: "/api/v1/task/{taskID}/activate",
  method: "get",
  ...variables,
  signal
});
const cancelTask = (variables, signal) => documentsSchedulerFetch({
  url: "/api/v1/task/{taskID}/cancel",
  method: "get",
  ...variables,
  signal
});
const createQuickTaskOld = (variables, signal) => documentsSchedulerFetch({
  url: "/api/v1/templates/{templateID}/quick-task",
  method: "post",
  ...variables,
  signal
});
const createQuickTask = (variables, signal) => documentsSchedulerFetch({
  url: "/api/v1/templates/{templateID}/quick-task/v2",
  method: "post",
  ...variables,
  signal
});
const getRoutes = (variables, signal) => documentsSchedulerFetch({ url: "/api/v1/route", method: "get", ...variables, signal });
const getTemplateByURL = (variables, signal) => documentsSchedulerFetch({
  url: "/api/v1/templates/{templateURL}/",
  method: "get",
  ...variables,
  signal
});
const getRoute = (variables, signal) => documentsSchedulerFetch({ url: "/api/v1/route/{routeID}", method: "get", ...variables, signal });
const operationsByTag = {
  type: { getTypes, getType },
  operation: { getOperations, getOperation },
  template: {
    getTemplates,
    getTemplate,
    createQuickTaskOld,
    createQuickTask,
    getTemplateByURL
  },
  task: { getTasks, getTask, activateTask, cancelTask },
  route: { getRoutes, getRoute }
};
export {
  activateTask,
  cancelTask,
  createQuickTask,
  createQuickTaskOld,
  getOperation,
  getOperations,
  getRoute,
  getRoutes,
  getTask,
  getTasks,
  getTemplate,
  getTemplateByURL,
  getTemplates,
  getType,
  getTypes,
  operationsByTag
};
