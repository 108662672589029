export default {
  progress: {
    title: 'Добавление тестового номера',
  },
  left: {
    title: 'Добавьте номер телефона для приема тестовых сообщений.',
    subtitle: 'После добавления номера вам поступит телефонный звонок, последние 4 цифры номера которого – код подтверждения. Либо поступит смс с кодом подтверждения.',
    subsubtitle: 'Вы сможете использовать свой номер телефона для приема тестовых сообщений, также вы сможете добавить дополнительные тестовые номера в настройках аккаунта.',
  },
  form_code: {
    success: {
      text: 'Номер телефона подтвержден. Для продолжения нажмите "Готово"',
    },
  },
  submit: {
    progress: {
      title: 'Настраиваем ваш кабинет',
    },
  },
};
