export const state = () => ({
  tasks: {},
});

export const getters = {};

export const mutations = {
  addTask(store, {id, modal}) {
    store.tasks = {
      ...store.tasks,
      [id]: modal,
    };
  },
};

export const actions = {
  addTask({commit}, payload) {
    commit('addTask', payload);
  },
};
