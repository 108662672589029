import axios from 'axios';
import aesjs from 'aes-js';
import { AUTH_COOKIE } from '~/store/auth';
import ServiceWebsocketsGateway from '~/components/WebsocketsGateway/WebsocketsGateway';

interface AuthData {
  email: string;
  password: string;
}

export default class ReLogin {
  private store: any;
  private readonly aesKey: Uint8Array;

  constructor (store) {
    this.store = store;
    this.aesKey = new TextEncoder().encode(process.env.secretKey);
  }

  get itemKey (): string {
    return AUTH_COOKIE + '-relogin';
  }

  get cryptoCtr () {
    return new aesjs.ModeOfOperation.ctr(this.aesKey, new aesjs.Counter(5));
  }

  encrypt ({ email, password }: AuthData): void {
    if (!process.browser) { return; }
    if (!window || !window.localStorage) { return; }

    const data = JSON.stringify({ email, password });

    const encrypt = aesjs.utils.hex.fromBytes(this.cryptoCtr.encrypt(aesjs.utils.utf8.toBytes(data)));

    window.localStorage.setItem(this.itemKey, encrypt);
  }

  decrypt (): AuthData | boolean {
    const data = process.browser && window.localStorage.getItem(this.itemKey);
    if (!data) { return false; }

    const decrypt = aesjs.utils.utf8.fromBytes(this.cryptoCtr.decrypt(aesjs.utils.hex.toBytes(data)));

    return JSON.parse(decrypt);
  }

  async run () {
    const decrypt = this.decrypt();
    if (!decrypt) {
      return false;
    }

    const result = await this.store.dispatch('auth/login', decrypt);
    if (result.success) {
      const token = result.success.token;

      axios.defaults.headers.common.Authorization = 'Bearer ' + token;
      ServiceWebsocketsGateway.keepAlive(token);
      window.chatWidgetConfig = { token };
    }

    return result;
  }
}
