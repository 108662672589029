import chart from '~/locales/en/Dashboard/chart';
import scheduled_dispatch_document from '~/locales/en/Dashboard/ScheduledDispatchDocument';
import datepicker from '~/locales/en/Dashboard/Datepicker';
import block_trustpilot from '~/locales/en/Dashboard/BlockTrustpilot';
import offer_activate_trial_corporate_tariffs_for_new_users from '~/locales/en/Dashboard/OfferActivateTrialCorporateTariffsForNewUsers';
import completion_trial_corporate_tariff from '~/locales/en/Dashboard/CompletionTrialCorporateTariff';

export default {
  chart,
  scheduled_dispatch_document,
  datepicker,
  block_trustpilot,
  offer_activate_trial_corporate_tariffs_for_new_users,
  completion_trial_corporate_tariff,

  projects_title: 'Projects',

  upload_contacts: 'Upload contacts',
  create_template: 'Create template',
  create_dispatch: 'Create dispatch',
  check_the_stats: 'Check the stats',
  realtime_account_balance: 'Realtime account balance',

  info_title: 'Messaggio dashboard',
  info_body: 'Welcome to the dashboard of the multichannel messaging platform. <br><br>' +
    'To send business messages, you must create and get aproval of the Sender ID, submit the message template and upload the contact group.<br><br>' +
    'You can also send messages using the API - the settings depends on the project: messaging credentials and callbacks for recieving statuses.',

  your_manager: 'Your manager',
  phone_number: 'Phone number',

  knowledge_base_title: 'Knowledge base',
  knowledge_base_body: 'Know more about messaging: industry terminoligy and specifications in our glossary.',
  knowledge_base_btn_glossary: 'Glossary',
  knowledge_base_btn_messaging: 'Messaging in countries',

  project_empty: 'Create your first messaging project!',
  project_empty_description: 'Manage your messaging activities by creating senders, templates and launching dispatches. <br>You can set up a callback in a project settings to receive message status notifications.',
  project_empty_btn: 'Get started',

  quick_actions: 'Quick actions',
  not_specified: 'Not specified',

  last_dispatch_stats: 'The last dispatch stats',
  last_dispatch_stats_demodata: 'Demo data. You didn\'t launch anything.',
  last_dispatch_stats_sent: 'Sent',
  last_dispatch_stats_delivered: 'Delivered',
  last_dispatch_stats_seen: 'Seen',

  more: 'More...',

  corporate_plan: {
    title: 'Corporate Plan',
    free: {
      title: 'Free plan',
      upgrade: 'Upgrade',
    },
    until: 'until',
  },

  link_guides: {
    title: 'Section help',
  },
};
