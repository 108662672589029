export default {
  title: 'Головна',
  search_placeholder: 'Пошук',
  navigation: 'Меню',

  my_company: 'Моя компанія',
  my_balance: 'Мій баланс',
  my_account_settings: 'Мій акаунт',
  my_document: 'Мої документи',
  logout: 'Вихід',

  balance: 'Баланс',
  support_api: 'Документація по API',
  settings: 'Налаштування',
  check_settings: 'Перевірити налаштування',

  pending_approval: 'Очикування модерації',
  client_senders: 'Відправники',
  senders_gates: 'Відправники',
  templates: 'Шаблони',
  home: 'Головна',

  loading: 'Завантаження...',
  actions: 'Дії',
  create: 'Створити',
  edit: 'Редагувати',
  delete: 'Видалити',
  save: 'Зберегти',
  save_changes: 'Зберегти зміни',
  remove: 'Видалити',
  success: 'Готово',
  warning: 'Увага',
  error: 'Помилка',
  close: 'Закрити',
  cancel: 'Відміна',
  yes: 'Так',
  no: 'Ні',
  empty: 'Не вказано',
  for: 'for',
  continue: 'Продовжити',
  search: 'Пошук',

  status_all: 'Статуси',
  type_all: 'Типи',

  filter_q_placeholder: 'Назва',

  created_at: 'Створено',
  updated_at: 'Оновлено',
  deleted_at: 'Видалено',

  get_tenant: 'Проекти',

  tab_general_information: 'Основна інформація',
  tab_dispatch_document: 'Розсилки',
  tab_template: 'Шаблони',
  tab_client_sender: 'Відправники',
  tab_channel_and_gate_sender: 'Відправники',
  tab_project: 'Проекти',
  tab_statistic: 'Статистика',
  tab_group_contact: 'Групи контактів',

  table_empty_text: 'Пусто',

  date_time_same_today_at: 'Сьогодні в',
  date_time_same_yesterday_at: 'Вчора в',
  date_time_same_at: 'в',

  error403: 'Немає доступу.',

  available_on_corporate: 'Доступно на тарифному плані Corporate',

  error_array_max: 'Поле має містити не більше елементів.',
  error_phone: 'Номер телефону недійсний.',
};
