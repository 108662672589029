import preview from '~/locales/ru/DispatchDocument/Launch/Preview'
import scheduled from '~/locales/ru/DispatchDocument/Launch/Scheduled'
import smooth from '~/locales/ru/DispatchDocument/Launch/Smooth'
import duplicates_remove from '~/locales/ru/DispatchDocument/Launch/DuplicatesRemove'
import smart from '~/locales/ru/DispatchDocument/Launch/Smart'

export default {
  title: 'Подготовка к отправке',
  description: 'У вас не будет возможности прервать рассылку, если она уже началась.<br>Пожалуйста, подтвердите, что информация ниже корректна.',

  price_description: 'Сумма заблокируется на время проведения рассылки. <br/> Часть средств будет возвращена на баланс по мере обновления статусов сообщений.',

  launch_settings: 'Настройки рассылки',
  coming_soon: 'Скоро',

  btn_schedule_dispatch: 'Запланировать рассылку',
  btn_launch_the_dispatch: 'Запустить рассылку',

  submit_info: 'Подготовка к рассылке',
  submit_success: 'Рассылка началась',
  submit_schedule_success: 'Рассылка запланирована',

  preview,
  scheduled,
  smooth,
  duplicates_remove,
  smart,
}
