import axios from 'axios';

declare module ApiAuth {

  export interface Status {
    id: string;
    name: string;
    description: string;
  }

  export interface Me {
    id: string;
    tenant_id: string;
    email: string;
    verified: boolean;
    namespace: string;
    phone: string;
    first_name: string;
    second_name: string;
    last_name: string;
    timezone: string;
    status: Status;
    lang: string;
    avatar_image_url: string;
    two_factor_auth_enabled: boolean;
    created_at: number;
    updated_at: number;
  }

}

export default {
  me: () => axios.get<ApiAuth.Me>('/entities/api/v1/users/-/profile'),
};
