export default {
  btn: 'Отправить на тестовый телефон',

  title: 'Отправка шаблона на тестовые номера',

  phone_ids_empty: 'Телефоны не выбраны',

  submit: 'Отправить на тестовые номера',

  send_success: 'Сообщение было отправлено на тестовый телефон.',

  show_message_preview: 'Показать сообщение целиком',
  hide_message_preview: 'Свернуть сообщение',
}
