export default {
  title_browser: 'Вход',
  title: 'Авторизация',
  oauth_loading: 'OAuth вход...',
  email_placeholder: 'Email адрес',
  password_placeholder: 'Пароль',
  forgot_password: 'Забыли пароль?',
  confirmation: 'Google Auth код',
  confirmation_placeholder: 'Google Auth код',
  sign_in_btn: 'Войти',
  or_sign_in_with: 'или войдите с помощью',
  oa2_google: 'Google',
  oa2_facebook: 'Фейсбук',
  oa2_yandex: 'Яндекс',
  by_continuing: 'Продолжая, вы подтверждаете, что прочитали и согласны с <a href="https://messaggio.by/ru/terms-and-conditions/" target="_blank">Пользовательским соглашением</a>, <a href="https://messaggio.by/ru/privacy-policy/" target="_blank">Политикой конфиденциальности</a> и <a href="https://messaggio.by/ru/terms-and-conditions/contract-for-the-provision-of-messaging-services-offer/" target="_blank">Офертой</a>',
  error_max_rate_limiting_reached: 'Достигнут лимит попыток, попробуйте через некоторое время.',
  error_tenant_is_block: 'Пожалуйста, обратитесь в службу поддержки: <a href="mailto:support@messaggio.com">support@messaggio.com</a>',

  error403: {
    title: 'Ошибка авторизации',
    description: 'Логин или пароль указан неверно.',
  },
};
