import chart from '~/locales/uk/Dashboard/chart';
import scheduled_dispatch_document from '~/locales/uk/Dashboard/ScheduledDispatchDocument';
import datepicker from '~/locales/uk/Dashboard/Datepicker';
import block_trustpilot from '~/locales/uk/Dashboard/BlockTrustpilot';
import offer_activate_trial_corporate_tariffs_for_new_users from '~/locales/uk/Dashboard/OfferActivateTrialCorporateTariffsForNewUsers';
import completion_trial_corporate_tariff from '~/locales/uk/Dashboard/CompletionTrialCorporateTariff';

export default {
  chart,
  scheduled_dispatch_document,
  datepicker,
  block_trustpilot,
  offer_activate_trial_corporate_tariffs_for_new_users,
  completion_trial_corporate_tariff,

  projects_title: 'Проекти',

  upload_contacts: 'Завантажити контакти',
  create_template: 'Створити шаблон',
  create_dispatch: 'Створити розсилку',
  check_the_stats: 'Подивитись статистику',
  realtime_account_balance: 'Баланс вашого договору',

  info_title: 'Панель управління',
  info_body:
    'Ласкаво просимо до Messaggio - мультиканальної платформи розсилки повідомлень. <br><br>' +
    'Для відправки повідомлень від імені вашої компанії необхідно створити і активувати відправника, сформувати шаблон повідомлення і завантажити групу контактів.<br><br>' +
    'Ви також можете відправляти і отримувати повідомлення за допомогою API - налаштування залежать від проекту: логін і ключ доступу, а також адреса для отримання статусів доставки.',

  your_manager: 'Ваш менеджер',
  phone_number: 'Телефон',

  knowledge_base_title: 'База знань',
  knowledge_base_body:
    'Дізнайтесь більше про бізнес розсилки: найкращі практики, терміни та інші особливості.',
  knowledge_base_btn_glossary: 'Глосарій',
  knowledge_base_btn_messaging: 'Каталог країн',

  project_empty: 'Створіть проект для розсилок!',
  project_empty_description:
    'Створюйте імена відправників, шаблоны повідомлень і мультиканальні розсилки.<br>Ви також можете задати адресу для отримання статусів доставки повідомлень в налаштуваннях проекту.',
  project_empty_btn: 'Створити проект',

  quick_actions: 'Швидкі дії',
  not_specified: 'Не вказано',

  last_dispatch_stats: 'Недавня розсилка',
  last_dispatch_stats_demodata: 'Демо дані. Ви пока ще нічего не відправляли.',
  last_dispatch_stats_sent: 'Відправлено',
  last_dispatch_stats_delivered: 'Доставлено',
  last_dispatch_stats_seen: 'Переглянуто',

  corporate_plan: {
    title: 'Корпоративний тариф',
    free: {
      title: 'Стандартний тариф',
      upgrade: 'Розширити',
    },
    until: 'до',
  },

  link_guides: {
    title: 'Посібники',
  },
};
