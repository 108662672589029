export default {
  btn_upload: 'Upload document',
  btn_select: 'Select a previously uploaded one',
  description: 'You can upload an image that meets the requirements: 1600x1200px maximum image size, up to 2MB, or choose from a gallery of previously uploaded images.',
  video_btn_upload: 'Upload video',
  video_btn_select: 'Select a previously uploaded one',
  video_description: 'You can upload an image that meets the requirements: 1600x1200px maximum image size, up to 2MB, or select from a gallery of previously uploaded images.',

  gallery_title: 'Select from documents',
  items_empty: 'No files',
  select_gallery: 'Select a previously uploaded one',

  video: {
    btn_upload: 'Upload document',
    btn_select: 'Select a previously uploaded one',
    select_gallery: 'Select a previously uploaded one',
    description: 'You can upload a video that meets the requirements: up to 200 MB file size, 3gp, m4v, mov, mp4 format, or select any of previously uploaded videos.',

    error: {
      video_duration_calculation_failed: 'Video duration calculation failed.',
    },
  },

  viber: {
    btn_upload: 'Upload document',
    btn_select: 'Select a previously uploaded one',
    select_gallery: 'Select a previously uploaded one',
    description: 'You can upload a file that meets the requirements: Maximum file size 200Mb; File extension: .doc, .docx, .rtf, .dot, .dotx, .odt ,odf, .fodt, .txt, .info, .pdf, .xps, .pdax, .eps, .xls, .xlsx, .ods, .fods, .csv, .xlsm, .xltx.',
  },

  whatsapp: {
    btn_upload: 'Upload document',
    btn_select: 'Select a previously uploaded one',
    select_gallery: 'Select a previously uploaded one',
    description: 'You can upload a file that meets the requirements: Maximum file size 100Mb, or choose from a gallery of previously downloaded files.',
  },

  telegram: {
    btn_upload: 'Upload document',
    btn_select: 'Select a previously uploaded one',
    select_gallery: 'Select a previously uploaded one',
    description: 'You can upload a file that meets the requirements: Maximum file size 50Mb, file extension .gif; .pdf; .zip, or choose from a gallery of previously downloaded files.',
  },
};
