export default {
  progress: {
    title: 'Add test number',
  },
  left: {
    title: 'Add a phone number to receive test messages.',
    subtitle: 'After adding the number, you will receive a phone call with the last 4 digits of the number as the confirmation code. Or you will receive an SMS with a confirmation code.',
    subsubtitle: 'You will be able to use use your own phone number to receive test messages, and you can also add additional test numbers in your account settings.',
  },
  form_code: {
    success: {
      text: 'The phone number has been confirmed. To continue, click "Done"',
    },
  },
  submit: {
    progress: {
      title: 'Setting up your office',
    },
  },
};
