export default {
  title: 'Deposit amount',
  select_contract: 'Select the contract',
  agreement_since: 'Messaging agreement since',
  auto_payment_enabled: 'Auto payment enabled',
  contract_id_placeholder: 'Please select...',
  btn_checkout: 'Checkout',
  helper: 'You will be redirected to store.messaggio.by to complete the payment.',
  errors_amount_min_value_RUB: 'The deposit amount should be more than {amount} RUB',
  errors_amount_min_value_UAH: 'The deposit amount should be more than {amount} UAH',
  cid: {
    errors: {
      required: 'Please, specify the contract for crediting',
    },
  },
  amount: {
    errors: {
      required: 'Please indicate the amount for replenishment',
    },
  },
}
