export default {
  title: 'Изменить номер',
  title_btn: 'Изменить номер',
  title_btn_empty: 'Привязать телефон',
  title_form_code_btn: 'Ввести код подтверждения номера',

  phone_description: 'Укажите ваш номер телефона в международном формате.<br>Вы получите код в Viber приложении или по SMS.',
  phone: 'Номер телефона',
  phone_placeholder: 'Номер телефона',
  phone_submit: 'Получить код',

  code_description: 'Привязать телефон <b>{phone}</b> к аккаунту',
  code: 'Код',
  code_placeholder: 'Код',
  code_submit: 'Привязать телефон',
  code_change_phone_number: 'Изменить номер телефона для привязки',

  ok_title: 'Изменить номер',
  save_success: 'Номер привязан',
  save_success_exist: 'Телефон был ранее добавлен как тестовый. Верификация не требуется.',

  delete_confirm: 'Отвязать номер?',
  delete_success: 'Номер отвязан',

  on_hide_confirm: 'Вы уверены?',

  error_invalid_verification_code: 'Код подтверждения указан неверно.',
  error_verifications_phone_session_exist: 'Вы сможете повторно запросить код через {duration} секунд.',
  error_verifications_phone_attempt_max: 'Превышено количество попыток привязки номера. Свяжитесь со службой поддержки.',
  error_verifications_phone_exist: 'Номер телефона уже существует.',
  error_verifications_code: 'Указан неверный код подтверждения. Попытка {attempt} из {attempt_max}.',
  error_verifications_code_attempt_max: 'Привязка номера не удалась. Обратитесь в службу поддержки.',
  error_verifications_code_session_not_found: 'Истекло время ожидания кода подтверждения, запросите новый код.',
}
