import preview from '~/locales/uk/DispatchDocument/Launch/Preview';
import scheduled from '~/locales/uk/DispatchDocument/Launch/Scheduled';
import smooth from '~/locales/uk/DispatchDocument/Launch/Smooth';
import duplicates_remove from '~/locales/uk/DispatchDocument/Launch/DuplicatesRemove';
import smart from '~/locales/uk/DispatchDocument/Launch/Smart';

export default {
  title: 'Підготовка до відправки',
  description: 'У вас не буде можливості перервати розсилку, якщо вона вже почалась.<br>Будь ласка, підтвердьте, що інформація нижче коректна.',

  price_description: 'Сума заблокується на час проведення розсилки. <br/> Частина коштів буде повернена на баланс після оновлення статусів повідомлень.',

  launch_settings: 'Налаштування розсилки',
  coming_soon: 'Скоро',

  btn_schedule_dispatch: 'Запланувати розсилку',
  btn_launch_the_dispatch: 'Запустити розсилку',

  submit_info: 'Підготовка до розсилки',
  submit_success: 'Розсилка почалась',
  submit_schedule_success: 'Розсилку заплановано',

  preview,
  scheduled,
  smooth,
  duplicates_remove,
  smart,
};
