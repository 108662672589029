import api from '~/api';
import ObserverApi from "~/utils/ObserverApi";

export const state = () => ({});

export const getters = {};

export const mutations = {};

export const actions = {
  load_rcs_client_sender_id({commit, state, rootState}) {
    return api.documents.channel.list(1, 9999)
      .then(response => (response.data.Body || []).find(r => r.name === 'rcs'))
      .then(channel => Promise.all(Object.keys(rootState.projects).map(
        pid => api.documents.project.clientsenders(pid, 1, 9999, '', {
          channel: channel.id,
          status: 'active'
        }).then(response => (response.data.Body || []))
      )))
      .then(r => [].concat(...r));
  },
  verification_phone_rcs({commit, state}, data) {
    return api.testphone.phones.verification.rcs(data).then(response => {
      //console.info('api.testphone.phones.verification.rcs | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.testphone.phones.verification.rcs | error = ', error);
      throw error;
    });
  },
  verification_phone({commit, state}, data) {
    return api.testphone.phones.verification.post(data).then(response => {
      //console.info('api.testphone.phones.verification.post | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.testphone.phones.verification.post | error = ', error);
      throw error;
    });
  },
  verification_code({commit, state}, data) {
    return api.testphone.phones.verification.code(data).then(response => {
      //console.info('api.testphone.phones.verification.code | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.testphone.phones.verification.code | error = ', error);
      throw error;
    });
  },
  list({dispatch, commit, state}, {project_id = null, page = 1, pagesize = 10, sort = '', observer = null, relate = [], ...options} = {}) {
    if (!observer) observer = new ObserverApi(dispatch, relate);

    if (sort.length > 0) sort += ',';
    sort += 'id';
    return api.testphone.phones.list(page, pagesize, sort, options).then(async response => {
      //console.info('api.testphone.phones.list | response = ', response.data);
      let items = response.data.Body || [];

      items = items.map(item => {
        item.params = item.params.reduce((ret, item) => {
          ret[item.key] = item.value;
          return ret;
        }, {});
        return item;
      });

      return {
        items,
        totalRows: response.data.Meta ? response.data.Meta.TotalSize : 0,
      };
    }).catch(error => {
      console.error('api.testphone.phones.list | error = ', error);
      throw error;
    });
  },
  one({dispatch, commit, state}, {id, observer = null} = {}) {
    if (!observer) observer = new ObserverApi(dispatch);

    return api.testphone.phones.get(id).then(async response => {
      //console.info('api.testphone.phones.get | response = ', response.data);
      let item = response.data;

      item.params = item.params.reduce((ret, item) => {
        ret[item.key] = item.value;
        return ret;
      }, {});

      return item;
    }).catch(error => {
      console.error('api.testphone.phones.get | error = ', error);
      throw error;
    });
  },
  delete({commit, state}, {id}) {
    return api.testphone.phones.delete(id).then(response => {
      //console.info('api.testphone.phones.delete | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.testphone.phones.delete | error = ', error);
      throw error;
    });
  },
  personalise({dispatch, commit, state}, {id, params}) {
    return api.testphone.phones.personalise(id, {params}).then(async response => {
      //console.info('api.testphone.phones.personalise | response = ', response.data);
      return response.data;
    }).catch(error => {
      console.error('api.testphone.phones.personalise | error = ', error);
      throw error;
    });
  },
};
