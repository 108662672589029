export default {
  btn: 'Send to test phones',

  title: 'Sending a template to test numbers',

  phone_ids_empty: 'No phones selected',

  submit: 'Send to test phones',

  send_success: 'The message was sent to the testphone.',

  show_message_preview: 'Show entire message',
  hide_message_preview: 'Hide message preview',
}
