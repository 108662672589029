import { apiGatewayFetch } from "./apiGatewayFetcher.js";
const gettoken = (variables, signal) => apiGatewayFetch({ url: "/auth/login", method: "post", ...variables, signal });
const uploadimage = (variables, signal) => apiGatewayFetch({ url: "/images", method: "post", ...variables, signal });
const listtemplates = (variables, signal) => apiGatewayFetch({ url: "/templates", method: "get", ...variables, signal });
const createtemplate = (variables, signal) => apiGatewayFetch({ url: "/templates", method: "post", ...variables, signal });
const gettemplatebyid = (variables, signal) => apiGatewayFetch({ url: "/templates/{id}", method: "get", ...variables, signal });
const listclientsenders = (variables, signal) => apiGatewayFetch({ url: "/clientsenders", method: "get", ...variables, signal });
const createclientsender = (variables, signal) => apiGatewayFetch({
  url: "/projects/{projectId}/clientsenders",
  method: "post",
  ...variables,
  signal
});
const getclientsenderbycodeApiandprojectId = (variables, signal) => apiGatewayFetch({
  url: "/projects/{projectId}/clientsenders/{codeApi}",
  method: "get",
  ...variables,
  signal
});
const deactivateclientsenderbycodeApiandprojectId = (variables, signal) => apiGatewayFetch({
  url: "/projects/{projectId}/clientsenders/{codeApi}/deactivate",
  method: "post",
  ...variables,
  signal
});
const listchannels = (variables, signal) => apiGatewayFetch({ url: "/channels", method: "get", ...variables, signal });
const listprojects = (variables, signal) => apiGatewayFetch({ url: "/projects", method: "get", ...variables, signal });
const getprojectbyid = (variables, signal) => apiGatewayFetch({ url: "/projects/{id}", method: "get", ...variables, signal });
const listavailablecontactgrouptypes = (variables, signal) => apiGatewayFetch({
  url: "/contact_groups/group_types",
  method: "get",
  ...variables,
  signal
});
const listavailablecontactparameters = (variables, signal) => apiGatewayFetch({
  url: "/contact_groups/contact_parameters",
  method: "get",
  ...variables,
  signal
});
const listcontactgroups = (variables, signal) => apiGatewayFetch({ url: "/contact_groups", method: "get", ...variables, signal });
const createcontactgroup = (variables, signal) => apiGatewayFetch({ url: "/contact_groups", method: "post", ...variables, signal });
const getcontactgroupbyid = (variables, signal) => apiGatewayFetch({ url: "/contact_groups/{groupId}", method: "get", ...variables, signal });
const deletecontractgroupbyid = (variables, signal) => apiGatewayFetch({
  url: "/contact_groups/{groupId}",
  method: "delete",
  ...variables,
  signal
});
const listgroupcontacts = (variables, signal) => apiGatewayFetch({
  url: "/contact_groups/{groupId}/contacts",
  method: "get",
  ...variables,
  signal
});
const addcontacttogroup = (variables, signal) => apiGatewayFetch({
  url: "/contact_groups/{groupId}/contacts",
  method: "post",
  ...variables,
  signal
});
const getcontactfromgroupbyphone = (variables, signal) => apiGatewayFetch({
  url: "/contact_groups/{groupId}/contacts/{phone}",
  method: "get",
  ...variables,
  signal
});
const updatecontactfromgroup = (variables, signal) => apiGatewayFetch({
  url: "/contact_groups/{groupId}/contacts/{phone}",
  method: "put",
  ...variables,
  signal
});
const deletecontactfromgroup = (variables, signal) => apiGatewayFetch({
  url: "/contact_groups/{groupId}/contacts/{phone}",
  method: "delete",
  ...variables,
  signal
});
const getmessagestatusbymessageid = (variables, signal) => apiGatewayFetch({ url: "/statuses/{messageId}", method: "get", ...variables, signal });
const getmessagestatusbymessageidsbatch = (variables, signal) => apiGatewayFetch({ url: "/statuses/batch", method: "post", ...variables, signal });
const createsendersandregisrationbot = (variables, signal) => apiGatewayFetch({ url: "/bots/telegram", method: "post", ...variables, signal });
const getBlacklistDomains = (variables, signal) => apiGatewayFetch({ url: "/antifrod/domains", method: "get", ...variables, signal });
const addBlacklistDomains = (variables, signal) => apiGatewayFetch({ url: "/antifrod/domains", method: "post", ...variables, signal });
const deleteBlacklistDomains = (variables, signal) => apiGatewayFetch({ url: "/antifrod/domains", method: "delete", ...variables, signal });
const getTelegramGroups = (variables, signal) => apiGatewayFetch({
  url: "/clientsenders/{clientSenderId}/telegramgroups",
  method: "get",
  ...variables,
  signal
});
const convertFile = (variables, signal) => apiGatewayFetch({ url: "/converter/convert", method: "post", ...variables, signal });
const getConverterTask = (variables, signal) => apiGatewayFetch({ url: "/converter/task/{id}", method: "get", ...variables, signal });
const addConvertedFile = (variables, signal) => apiGatewayFetch({
  url: "/contacts/upload-converted",
  method: "post",
  ...variables,
  signal
});
const updateDispatch = (variables, signal) => apiGatewayFetch({ url: "/dispatch/{id}", method: "put", ...variables, signal });
const getDispatchHistory = (variables, signal) => apiGatewayFetch({
  url: "/dispatch/{id}/status-history",
  method: "get",
  ...variables,
  signal
});
const getRules = (variables, signal) => apiGatewayFetch({ url: "/virtual-gate/rules", method: "get", ...variables, signal });
const operationsByTag = {
  authorization: { gettoken },
  images: { uploadimage },
  templates: { listtemplates, createtemplate, gettemplatebyid },
  clientsenders: {
    listclientsenders,
    createclientsender,
    getclientsenderbycodeApiandprojectId,
    deactivateclientsenderbycodeApiandprojectId
  },
  channels: { listchannels },
  projects: { listprojects, getprojectbyid },
  contactGroups: {
    listavailablecontactgrouptypes,
    listavailablecontactparameters,
    listcontactgroups,
    createcontactgroup,
    getcontactgroupbyid,
    deletecontractgroupbyid,
    listgroupcontacts,
    addcontacttogroup,
    getcontactfromgroupbyphone,
    updatecontactfromgroup,
    deletecontactfromgroup
  },
  messageStatuses: {
    getmessagestatusbymessageid,
    getmessagestatusbymessageidsbatch
  },
  bots: { createsendersandregisrationbot },
  antifrodDomains: {
    getBlacklistDomains,
    addBlacklistDomains,
    deleteBlacklistDomains
  },
  telegramgroup: { getTelegramGroups },
  converter: { convertFile, getConverterTask },
  contacts: { addConvertedFile },
  dispatch: { updateDispatch, getDispatchHistory },
  virtualGate: { getRules }
};
export {
  addBlacklistDomains,
  addConvertedFile,
  addcontacttogroup,
  convertFile,
  createclientsender,
  createcontactgroup,
  createsendersandregisrationbot,
  createtemplate,
  deactivateclientsenderbycodeApiandprojectId,
  deleteBlacklistDomains,
  deletecontactfromgroup,
  deletecontractgroupbyid,
  getBlacklistDomains,
  getConverterTask,
  getDispatchHistory,
  getRules,
  getTelegramGroups,
  getclientsenderbycodeApiandprojectId,
  getcontactfromgroupbyphone,
  getcontactgroupbyid,
  getmessagestatusbymessageid,
  getmessagestatusbymessageidsbatch,
  getprojectbyid,
  gettemplatebyid,
  gettoken,
  listavailablecontactgrouptypes,
  listavailablecontactparameters,
  listchannels,
  listclientsenders,
  listcontactgroups,
  listgroupcontacts,
  listprojects,
  listtemplates,
  operationsByTag,
  updateDispatch,
  updatecontactfromgroup,
  uploadimage
};
