export default {
  title_browser: 'Реєстрація',
  title: 'Створення акаунту',
  subtitle: 'Всі поля обов`язкові для заповнення',
  tenant_name_placeholder: 'Назва компанії',
  user_name_placeholder: 'Ваше ім`я',
  user_email_placeholder: 'Email адреса',
  user_password: 'Пароль',
  user_password_confirmed: 'Ще раз пароль',
  register_btn: 'Зареєструватися',
  terms_and_conditions: 'Я згоден з <a href="https://messaggio.by/terms-and-conditions/" target="_blank">Умовами використання</a>',
  privacy_policy: 'Я згоден з <a href="https://messaggio.by/privacy-policy/" target="_blank">Політикою конфіденційності</a>',
  by_continuing: 'Продовжуючи, ви підтверджуєте, що прочитали та згодні з <a href="https://messaggio.by/terms-and-conditions/" target="_blank">Угодою користувача</a>, <a href="https://messaggio.by/privacy-policy/" target="_blank">Політикою конфіденційності</a> та <a href="https://messaggio.by/terms-and-conditions/contract-for-the-provision-of-messaging-services-offer/" target="_blank">Офертою</a>',
  or_registration_in_with: 'або зареєструйтесь за допомогою',
};
