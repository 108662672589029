import Vue from 'vue';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import merge from 'lodash.merge';
import api from '@/api';
import { RootState } from '@/store';
import { getContactsDispatchesInfoPromises } from '@/store/channel-and-gate/content';
import { getTemplateContentOnePromises } from '@/store/template';
import { getChannelOnePromises } from '@/store/channel-and-gate/channel-and-gate/channel';
import ObserverApi from '@/utils/ObserverApi';

export const state = () => ({
  // Только project, без зависимостей
  list: {
    loading: {},
    error: {},
    item: {},
  },
});

export type ProjectState = ReturnType<typeof state>

export const getters: GetterTree<ProjectState, RootState> = {};

export const mutations: MutationTree<ProjectState> = {
  SET_LOADING (state, { id, loading }) {
    Vue.set(state.list.loading, id, loading);
  },
  SET_ERROR (state, { id, error }) {
    if (error) {
      Vue.set(state.list.error, id, error);
    } else {
      Vue.delete(state.list.error, id);
    }
  },
  SET_ITEM (state, item) {
    Vue.set(state.list.item, item.id, item);
  },
};

export const actions: ActionTree<ProjectState, RootState> = {
  list ({ commit, dispatch }, {
    page = 1,
    pagesize = 10,
    sort = '',
    observer = null,
    ...options
  } = {}) {
    if (!observer) {
      observer = new ObserverApi(dispatch);
    }

    if (sort.length > 0) {
      sort += ',';
    }
    sort += 'id';

    return api.documents.project.list(page, pagesize, sort, options)
      .then(async (res) => {
        const projects = res.data.Body || [];
        const totalRows = res.data.Meta.TotalSize;

        await Promise.all([
          ...projects.map(project => getProjectTrailerStyleOnePromises(observer, project, project.id)),
        ]);

        projects.map(p => commit('SET_ITEM', p));

        return { items: projects, totalRows };
      }).catch((error) => {
        console.error('store/project/list | error = ', error);
        throw error;
      });
  },
  one ({ dispatch, commit }, { id, observer = null } = {}) {
    if (!observer) {
      observer = new ObserverApi(dispatch);
    }

    commit('SET_LOADING', { id, loading: true });

    return api.documents.project.get(id)
      .then(async (response) => {
        // console.info('api.documents.project.one | response = ', response.data);
        const project = response.data;

        project.authorized_ip_ = project.authorized_ip.map(s => s.replace(/\/32$/, '')).join('\n');

        await Promise.all([
          getProjectTrailerStyleOnePromises(observer, project, project.id),
        ]);

        commit('SET_ITEM', project);

        return project;
      }).catch((error) => {
        console.error('store/project/one | error = ', error);
        commit('SET_ERROR', { id, error });
        throw error;
      }).finally(() => {
        commit('SET_LOADING', { id, loading: false });
      });
  },
  create ({ commit }, { data }) {
    data = { ...data };
    delete data.id;
    return api.documents.project.post(data)
      .then((response) => {
        // console.info('api.documents.project.post | response = ', response.data);
        const project = response.data;
        commit('SET_ITEM', project);
        return project;
      }).catch((error) => {
        console.error('store/project/create | error = ', error);
        throw error;
      });
  },
  edit ({ commit }, { id, data }) {
    data = { ...data };
    return api.documents.project.put(id, data)
      .then((response) => {
        // console.info('api.documents.project.put | response = ', response.data);
        const project = response.data;
        commit('SET_ITEM', project);
        return project;
      }).catch((error) => {
        console.error('store/project/edit | error = ', error);
        throw error;
      });
  },
  delete (_, { id }) {
    return api.documents.project.delete(id)
      .then((response) => {
        // console.info('api.documents.project.put | response = ', response.data);
        return response.data;
      }).catch((error) => {
        console.error('store/project/delete | error = ', error);
        throw error;
      });
  },
  listDispatchDocuments ({ dispatch }, { id, page, pagesize, observer = null } = {}) {
    if (!observer) {
      observer = new ObserverApi(dispatch);
    }

    return api.documents.project.dispatch_document(id, page, pagesize).then(async (response) => {
      // console.info('api.documents.project.dispatch_document | response = ', response.data);
      let items = response.data.Body || [];
      items = await Promise.all(items.map(item => getContactsDispatchesInfoPromises(observer, item)));
      return {
        items,
        totalRows: response.data.Meta.TotalSize,
      };
    }).catch((error) => {
      console.error('store/project/listDispatchDocuments | error = ', error);
      throw error;
    });
  },
  listTemplates ({ dispatch }, { id, page, pagesize, observer = null } = {}) {
    if (!observer) {
      observer = new ObserverApi(dispatch);
    }

    return api.documents.project.templates.list(id, page, pagesize).then(async (response) => {
      // console.info('api.documents.project.templates | response = ', response.data);
      let items = response.data.Body || [];
      items = await Promise.all(items.map(item => getTemplateContentOnePromises(observer, item, 'id')));
      return {
        items,
        totalRows: response.data.Meta.TotalSize,
      };
    }).catch((error) => {
      console.error('store/project/listTemplates | error = ', error);
      throw error;
    });
  },
  listClientSender ({ dispatch }, { id, page, pagesize, q = null, observer = null } = {}) {
    if (!observer) {
      observer = new ObserverApi(dispatch);
    }

    return api.documents.project
      .clientsenders(id, page, pagesize, { q })
      .then(async (response) => {
        // console.info('api.documents.project.clientsender | response = ', response.data);
        const items = response.data.Body || [];
        // items = await Promise.all(items.map((item) => getCompanyOnePromises(dispatch, item)));
        await Promise.all([
          ...items.map(item => getChannelOnePromises(observer, item)),
          ...items.map(item => getProjectOnePromises(observer, item, item.id)),
        ]);
        return {
          items,
          totalRows: response.data.Meta.TotalSize,
        };
      }).catch((error) => {
        console.error('store/project/listClientSender | error = ', error);
        throw error;
      });
  },
};

export function getProjectOnePromises (observer, item, id) {
  return new Promise((resolve) => {
    if (!item) {
      resolve(null);
    } else if (id) {
      observer.subscribeObserver(
        'project/one',
        { id },
        (project) => {
          item.project = project;
          resolve(item);
        },
      );
    } else {
      item.project = null;
      resolve(item);
    }
  });
}

export function getProjectTrailerStyleOnePromises (observer, item, id) {
  return new Promise((resolve) => {
    if (!item) {
      resolve(null);
    } else if (id) {
      observer.subscribeObserver(
        'trailer/setting/list',
        { entity_id: id, key: 'style.*', pagesize: 99999 },
        (trailer) => {
          const style = trailer.items.reduce((r, el) => {
            r[el.key.substr(6)] = el;
            return r;
          }, { bg_color: { value: '' } });
          item = merge(item, { trailer: { style } });
          resolve(item);
        },
      );
    } else {
      resolve(item);
    }
  });
}

export function getProjectOnePromisesV2 (observer, item, callBackRelate = () => ({}), idName = 'project_id') {
  return new Promise((resolve) => {
    if (!item) {
      resolve(null);
      return;
    }
    if (!item[idName] || !item[idName].length) {
      item.project = null;
      resolve(item);
      return;
    }
    observer.subscribeObserver(
      'project/one',
      { id: item[idName], callBackRelate },
      (project) => {
        item.project = project;
        resolve(item);
      },
    );
  });
}
